var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center w-100",attrs:{"id":"searchAnchor"}},[_c('div',{staticClass:"library__container w-100",style:({ 'max-width': '1240px', width: '100%' })},[(_vm.isSkeleton)?_c('div',{staticClass:"mb-3 d-flex flex-wrap --gap justify-content-center"},_vm._l((12),function(i){return _c('div',{key:i + 'skeleton'},[_c('course-skeleton')],1)}),0):_vm._e(),(!_vm.isSkeleton)?_c('div',{staticClass:"w-100 d-flex flex-wrap justify-content-center flex-row --gap",class:{
        '--gap': _vm.libraryItems && _vm.libraryItems.length !== 0,
        '--gap__x':
          _vm.events &&
          _vm.events.length === 0 &&
          !_vm.searchItemQuery &&
          _vm.selectedFilter.length === 0,
      }},[_c('div',{staticClass:"d-flex justify-content-between w-100"},[(
            _vm.events &&
            _vm.events.length !== 0 &&
            !_vm.searchItemQuery &&
            _vm.selectedFilter.length === 0
          )?_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center",style:({ 'max-width': '1240px' })},[_c('h6',{staticClass:"w-100",staticStyle:{"font-size":"2em !important","font-weight":"600","color":"black"}},[_vm._v(" For You ")])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center w-100",class:_vm.selectedFilter.length > 0
              ? 'justify-content-between'
              : 'justify-content-end'},[_c('div',{staticClass:"d-flex align-items-center justify-content-end w-100"},[_c('img',{staticClass:"btn filter",attrs:{"src":require("@/assets/images/icons/search.svg"),"alt":"search"},on:{"click":function($event){_vm.$store.commit('modals/OPEN_SEARCH_POPUP', !_vm.searchPopup);
                _vm.$store.commit('library/UPDATE_SEARCH_ITEM_QUERY', '');
                _vm.blockBodyScroll(true);}}}),(
                (_vm.libraryItems && _vm.libraryItems.length !== 0) ||
                (_vm.events && _vm.events.length !== 0) ||
                _vm.searchItemQuery ||
                _vm.selectedFilter.length > 0
              )?_c('img',{staticClass:"btn ml-50 ml-md-75",attrs:{"src":require("@/assets/images/icons/filter.svg"),"alt":"filter"},on:{"click":function($event){_vm.$store.commit('modals/OPEN_FILTER_POPUP', !_vm.filterPopup);
                _vm.blockBodyScroll(true);}}}):_vm._e(),_c('button',{staticClass:"btn ml-50 ml-md-75 distribute",on:{"click":function($event){_vm.$store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', true);
                _vm.blockBodyScroll(true);}}},[_vm._v(" Distribute ")])])])]),_c('div',{staticClass:"d-flex align-items-start w-100 flex-wrap",staticStyle:{"gap":"1rem"}},_vm._l((_vm.selectedFilter),function(filter){return _c('div',{key:filter.value,staticClass:"pill pill-filter d-flex align-items-center"},[_vm._v(" "+_vm._s(filter.label)+" "),_c('div',{staticClass:"cross align-items-center justify-content-center cursor-pointer",on:{"click":function($event){return _vm.removeFilter(filter)}}},[_vm._v(" X ")])])}),0),(
          _vm.events &&
          _vm.events.length > 0 &&
          !_vm.searchItemQuery &&
          _vm.selectedFilter &&
          _vm.selectedFilter.length === 0
        )?_c('EventSliders',{attrs:{"events":_vm.events}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-center w-100"},[(
            _vm.libraryItems &&
            _vm.libraryItems.length > 0 &&
            !_vm.searchItemQuery &&
            _vm.selectedFilter &&
            _vm.selectedFilter.length === 0
          )?_c('div',{staticClass:"flex w-100 mt-25 mt-lg-2",style:({ 'max-width': '1240px' })},[_c('h6',{staticStyle:{"font-size":"2em !important","font-weight":"600","color":"black"}},[_vm._v(" Explore ")]),_c('hr')]):_vm._e(),_c('div',{staticClass:"mt-50 flex-wrap --gap",style:({ 'max-width': '1240px' })},_vm._l((_vm.libraryItems),function(libraryItem,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.libraryItems && _vm.libraryItems.length > 0),expression:"libraryItems && libraryItems.length > 0"}],key:index},[(
                (libraryItem &&
                  (libraryItem.itemType || libraryItem.data.itemType)) ===
                'event'
              )?_c('EventCard',{attrs:{"event":libraryItem.data || libraryItem}}):_c('Courses',{attrs:{"libraryItem":libraryItem.data || libraryItem}})],1)}),0),(
            (!_vm.libraryItems || (_vm.libraryItems && _vm.libraryItems.length === 0)) &&
            (!_vm.events || (_vm.events && _vm.events.length === 0))
          )?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center w-100 empty__library",style:({ 'max-width': '1240px' })},[_c('img',{staticStyle:{"scale":"1"},attrs:{"src":require("@/assets/images/library/empty-development.svg"),"alt":"Empty Items"}}),(_vm.selectedFilter && _vm.selectedFilter.length > 0)?_c('h2',{staticClass:"heading__black empty__library__text mt-50 mb-50 mb-md-3"},[_vm._v(" Alas, no results to display. Why not to try a different filter ? ")]):(_vm.searchItemQuery)?_c('h2',{staticClass:"heading__black empty__library__text mt-50 mb-50 mb-md-3"},[_vm._v(" No match found for \""+_vm._s(_vm.searchItemQuery)+"\".Why not refine your search criteria ? ")]):_c('h2',{staticClass:"heading__black empty__library__text mt-50 mb-50 mb-md-3"},[_vm._v(" Your library is being curated! ")])]):_vm._e()])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }