<template>
  <div class="distribute__item__card">
    <div
      class="distribute__item__card__cross cursor-pointer"
      @click="removeCourse(libraryItem)"
    >
      X
    </div>

    <div class="distribute__item__card__img">
      <img
        :src="libraryItem.imageUrl"
        class=""
        @click="redirectPage(libraryItem)"
        @error="randomFeedImage(libraryItem, libraryItem.itemId)"
      />
    </div>

    <div class="px-2 pt-2 pb-1 distribute__item__card__info">
      <div class="distribute__item__card__info__head mb-1">
        <img
          :src="libraryItem.sourceLogoUrl"
          :alt="libraryItem.source ? libraryItem.source : 'GetBoarded'"
          @error="altSrcImage"
          class="mr-1"
        />

        <h5>{{ libraryItem.source ? libraryItem.source : "GetBoarded" }}</h5>
      </div>

      <div class="distribute__item__card__info__desc">
        <h6 class="pop">
          {{ libraryItem.title }}
        </h6>
        <p class="inter" v-if="typeof libraryItem.description === 'string'">
          {{ libraryItem.description }}
        </p>
        <div class="inter" v-else>
          <div
            v-for="(data, index) in libraryItem.description"
            :key="index"
            v-html="data"
          ></div>
        </div>
      </div>
      <div
        class="distribute__item__card__info__tags"
        v-if="libraryItem.behaviors && libraryItem.behaviors.length"
      >
        <div>
          <span
            class="text-capitalize"
            v-for="(behavior, index) in libraryItem.behaviors.slice(0, 1)"
            :key="index"
            >#{{ behavior }}</span
          >
        </div>

        <div>
          <img
            v-if="libraryItem.format.toLowerCase() === 'read'"
            src="@/assets/images/read-icon.svg"
          />
          <img
            v-if="libraryItem.format.toLowerCase() === 'listen'"
            src="@/assets/images/hear-icon.svg"
          />
          <img
            v-if="libraryItem.format.toLowerCase() === 'watch'"
            src="@/assets/images/video-icon.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import designMixin from "@/mixins/designMixin.js";
export default {
  name: "DistributeCourseCard",
  mixins: [designMixin],
  data() {
    return {};
  },
  props: {
    libraryItem: {
      required: true,
      type: Object,
    },
  },
  computed: {
    selectedItem() {
      return this.$store.state.library.selectedItem;
    },
  },
  methods: {
    randomFeedImage(item, index) {
      let i = 15; // i = number of given random images
      let alternateImgUrl = require(`@/assets/images/library/blank-feed/${[
        Math.floor(Math.random() * i),
      ]}.png`);
      item.imageUrl = alternateImgUrl;
      this.$set(this.libraryItem, index, item);
    },
    altSrcImage(e) {
      return (e.target.src = require(`@/assets/images/library/getboarded-logo.svg`));
    },
    removeCourse(item) {
      event.stopPropagation();
      this.$store.commit("library/UPDATE_DISTRIBUTED_ITEM_LIST", item);
    },
    redirectPage(item) {
      const { gbuid, gborgid } = this.$route.query;
      let queryParams = {
        itemId: item.itemId,
        type: item.itemType || item.type,
        gbuid: gbuid,
        gborgid: gborgid,
      };
      const routeData = this.$router.resolve({
        name: "Redirect Page",
        query: queryParams,
      });
      return window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap");

.inter {
  font-family: "Inter", sans-serif;
}
.pop {
  font-family: "Poppins", sans-serif;
}
</style>

<style lang="scss">
.distribute__item__card {
  display: flex;
  border-radius: 0.375rem;
  border: 2px solid #efefef;
  overflow: hidden;
  position: relative;
  height: 100%;
  justify-content: end;

  &__cross {
    position: absolute;
    height: 3rem;
    width: 3rem;
    margin-bottom: -4rem;
    z-index: 5;
    padding: 0.7rem;
    background-color: #ff6584;
    color: #fff;
    border-radius: 0 0 0.5rem 0;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    left: 0;
  }

  &__img {
    display: flex;
    height: auto;
    object-fit: cover;

    img {
      border-radius: 0.375rem;
      width: 15rem;
      height: 100% !important;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    // justify-content: start;

    &__head {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: fit-content;

      img {
        width: auto;
        height: 1.5rem;
      }
    }

    &__desc {
      margin: 0rem;
      padding: 0rem;

      h6 {
        color: #000;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.375rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }

      p {
        color: #7b818e;
        font-size: 0.875rem;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }

    &__tags {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid #f2f2f2;
      padding-top: 1rem;
      flex-wrap: wrap;
      gap: 1rem;

      span {
        color: #a3a3a3;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: #f0f0f0;
        padding: 0.5rem 0.8rem;
        border-radius: 1rem;
        width: fit-content !important;
        white-space: nowrap;
      }

      div {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        img {
          width: 2rem;
          height: 2rem;
          margin-left: -0.7rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 640px) {
  .distribute__item__card {
    flex-wrap: wrap;

    &__img {
      width: 100%;
      img {
        width: 100%;
        max-height: 20rem;
        object-fit: cover;
      }
    }

    &__info {
      width: 100%;
    }
  }
}
</style>
