import {
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Medium,
  Github,
} from "@/assets/images/icons/social-icons/index.js";

let socialHandles = [
  {
    icon: Linkedin,
    link: "https://www.linkedin.com/company/getboarded/mycompany/",
  },
  {
    icon: Twitter,
    link: "https://twitter.com/gtbrdd",
  },
  {
    icon: Facebook,
    link: "https://www.facebook.com/Getboarded",
  },
  {
    icon: Instagram,
    link: "https://www.instagram.com/getboarded/",
  },
  {
    icon: Medium,
    link: "https://medium.com/@gtbrdd",
  },
  {
    icon: Github,
    link: "/",
  },
];

export default socialHandles;
