<template>
  <div
    class="d-flex flex-column justify-content-center w-100 align-items-center"
  >
    <!-- <Header /> -->
    <Navbar />
    <Library />
    <Footer />
    <DistributeItem v-if="distributeCoursePopup" />
    <AddCustomCourse v-if="addCustomCoursePopup" />
    <FilterModal v-if="filterPopup" :itemSources="this.itemSources" />
    <SearchModal v-if="searchPopup" />
  </div>
</template>

<script>
// import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";
import Library from "../library/Library.vue";
import Footer from "@/components/Footer.vue";
import DistributeItem from "@/modals/DistributeItem.vue";
import AddCustomCourse from "@/modals/AddCustomCourse.vue";
import FilterModal from "@/modals/FilterModal.vue";
import SearchModal from "@/modals/SearchModal.vue";
export default {
  components: {
    // Header,
    Navbar,
    Library,
    DistributeItem,
    AddCustomCourse,
    FilterModal,
    SearchModal,
    Footer,
    // Filters,
  },
  data() {
    return {
      itemSources: [],
    };
  },
  computed: {
    distributeCoursePopup() {
      return this.$store.state.modals.distributeCoursePopup;
    },
    filterPopup() {
      return this.$store.state.modals.filterPopup;
    },
    searchPopup() {
      return this.$store.state.modals.searchPopup;
    },
    addCustomCoursePopup() {
      return this.$store.state.modals.addCustomCoursePopup;
    },
  },
  mounted() {
    this.getFilterSources();
  },
  methods: {
    getFilterSources() {
      this.$store
        .dispatch("library/getSources")
        .then((res) => {
          if (typeof res === "object" && res?.length > 0) {
            res.sort((a, b) => {
              const labelA = a.label.toLowerCase();
              const labelB = b.label.toLowerCase();

              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            });

            this.itemSources = res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
