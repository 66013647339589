<template>
  <div
    class="py-2 md-pd d-flex align-items-center w-100 h-100 justify-content-center distribute-item__background"
    @click="
      $store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', false);
      $store.commit('library/SELECT_ITEM_INSTRUCTIONS', []);
      blockBodyScroll(false);
    "
    @click.capture="isOverlayExist"
  >
    <vue-perfect-scrollbar
      @click.stop="checkClickOnContainer"
      class="w-60 distribute-item position-relative"
    >
      <div
        class="d-flex align-items-start justify-content-between fixed-container"
        :style="isOverLay ? 'z-index:1' : ''"
      >
        <div>
          <h2 class="font-weight-bolder heading__black">Distribute Tasks</h2>
          <!-- <editor/> -->
          <h4 class="font-weight-bolder heading__gray">
            Distribute tasks to your trainee and colleagues
          </h4>
        </div>
        <div
          class="cross-icon d-flex align-items-center justify-content-center cursor-pointer"
          @click="
            $store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', false);
            blockBodyScroll(false);
          "
        >
          <img src="@/assets/images/icons/cross-icon.svg" alt="" />
        </div>
      </div>

      <main
        class="distribute-item__container"
        :style="{ gap: selectedItem && selectedItem.length > 0 ? '2rem' : '0' }"
      >
        <section
          class="left"
          :class="{ isRight: selectedItem && selectedItem.length > 0 }"
        >
          <div>
            <div class="heading">Distribute to</div>
            <main
              v-if="appView === 'userView'"
              class="dropdown w-100 mt-25"
              ref="listContainer"
            >
              <b-form-input
                size="lg"
                class="dropdown__search"
                v-model="searchQuery"
                @focus="toggleListVisibility(true)"
                placeholder="Search User"
                autocomplete="off"
              />
              <div class="dropdown__icons">
                <img
                  class="cursor-pointer"
                  @click="toggleListVisibility(!listVisible)"
                  src="@/assets/images/icons/user.svg"
                  alt=""
                />
                <img
                  @click="toggleListVisibility(!listVisible)"
                  class="cursor-pointer"
                  src="@/assets/images/icons/arrow-drop.svg"
                  alt=""
                />
              </div>

              <vue-perfect-scrollbar
                class="dropdown__content position-absolute"
                :class="listVisible ? 'd-block' : 'd-none'"
              >
                <ul v-if="filteredUsers.length > 0" class="p-0 my-50">
                  <li
                    v-for="user in filteredUsers"
                    :key="user._id"
                    class="align-items-center"
                    :class="{
                      option__selected: isUserSelected(user),
                      'd-none': userData._id === user.userId,
                      'd-flex': userData._id !== user.userId,
                    }"
                    @click="selectUser(user)"
                  >
                    <b-avatar
                      class="mr-50"
                      v-if="user.userData.profilePictureUrl"
                      :src="`${constants.s3Bucket}/editor/profile-pictures/${user.userData.profilePictureUrl}`"
                    />
                    <b-avatar
                      class="mr-50"
                      v-else
                      :src="`https://source.boringavatars.com/beam/100/${avatarNameGenerator(
                        user.userData.firstName,
                        user.userData.lastName
                      )}`"
                    />
                    <div class="d-flex flex-column">
                      <span class="user-name">
                        {{ user.userData.firstName }}
                        {{ user.userData.lastName }}
                      </span>
                      <span>
                        {{ user.userData.email }}
                      </span>
                    </div>
                  </li>
                </ul>

                <ul v-if="filteredUsers.length === 0" class="p-0 my-50">
                  <div class="d-flex justify-content-center user-name px-75">
                    User not found.
                  </div>
                </ul>
              </vue-perfect-scrollbar>
            </main>

            <!-- org segments dropdown  -->
            <main v-else class="dropdown w-100 mt-25" ref="listContainer">
              <b-form-input
                v-model="searchSegmentQuery"
                size="lg"
                class="dropdown__search cursor-pointer"
                @focus="toggleListVisibility(true)"
                placeholder="Search Segment"
                autocomplete="off"
                style="background-color: white"
              />
              <div class="dropdown__icons">
                <!-- <img
                  class="cursor-pointer"
                  @click="toggleListVisibility(!listVisible)"
                  src="@/assets/images/icons/user.svg"
                  alt=""
                /> -->
                <img
                  @click="toggleListVisibility(!listVisible)"
                  class="cursor-pointer"
                  src="@/assets/images/icons/arrow-drop.svg"
                  alt=""
                />
              </div>
              <vue-perfect-scrollbar
                class="dropdown__content --org position-absolute"
                :class="listVisible ? 'd-block' : 'd-none'"
              >
                <div v-if="filteredSegments.length > 0" class="p-0 my-50">
                  <div
                    v-for="(segment, index) in filteredSegments"
                    :key="index"
                    class="d-flex align-items-center"
                  >
                    <div class="segment__container w-100">
                      <h5 class="m-0 title">
                        {{ segment.segmentTitle }}
                      </h5>
                      <div
                        v-for="(value, index) in segment.segmentValues"
                        :key="index"
                        class="value"
                        @click="selectSegment(value)"
                        :class="{
                          value__selected: isSegmentSelected(value),
                        }"
                      >
                        <span v-if="value.name === 'user'">
                          <b-avatar
                            class="mr-50"
                            v-if="value.picture"
                            :src="`${constants.s3Bucket}/editor/profile-pictures/${value.picture}`"
                          />
                          <b-avatar
                            class="mr-50"
                            v-else
                            :src="`https://source.boringavatars.com/beam/100/${avatarNameGenerator(
                              value.label
                            )}`"
                          />
                        </span>
                        {{ value.label }}
                      </div>
                    </div>
                  </div>
                </div>

                <ul v-if="filteredSegments.length === 0" class="p-0 my-50">
                  <div class="d-flex justify-content-center user-name px-75">
                    Segment not found.
                  </div>
                </ul>
              </vue-perfect-scrollbar>
            </main>
          </div>
          <div
            v-if="selectedUsers.length || selectedSegment.length > 0"
            class="users__selected mt-1"
          >
            <div v-if="selectedSegment.length > 0">
              <h4 class="text mt-75 mb-25">Tags Selected</h4>
              <div class="d-flex flex-wrap tags__container">
                <div
                  class="pill pill-filter m-25 d-flex align-items-center justify-items-center"
                  v-for="(segment, index) in selectedSegment"
                  :key="index"
                >
                  {{ segment.label }}
                  <div
                    @click="removeSelectedSegment(index)"
                    class="cross align-items-center justify-content-center cursor-pointer"
                  >
                    X
                  </div>
                </div>
              </div>
            </div>
            <h4
              v-if="
                !searchingUser &&
                selectedSegment.length > 0 &&
                selectedUsers.length === 0
              "
              class="text mt-25"
            >
              No user found
            </h4>

            <h4 v-if="selectedUsers.length > 0" class="text mt-75">
              {{ selectedUsers.length }}
              {{ selectedUsers.length > 1 ? "users" : "user" }} selected
              <span v-if="selectedItem && selectedItem.length">
                for {{ selectedItem && selectedItem.length }} course
                distribution
              </span>
            </h4>
            <div
              class="d-flex align-items-center position-relative"
              v-if="selectedUsers.length > 0"
            >
              <div
                class="avatar__list"
                v-for="user in selectedUsers.slice(0, 10)"
                :key="user._id"
              >
                <b-avatar
                  class="cursor-pointer --avatar"
                  v-if="user.userData && user.userData.profilePictureUrl"
                  :src="`${constants.s3Bucket}/editor/profile-pictures/${user.userData.profilePictureUrl}`"
                  v-b-tooltip.hover
                  :title="`${
                    user.userData && user.userData.firstName
                      ? user.userData.firstName
                      : ''
                  } ${
                    user.userData && user.userData.lastName
                      ? user.userData.lastName
                      : ''
                  }`"
                />
                <b-avatar
                  class="cursor-pointer --avatar"
                  v-b-tooltip.hover
                  :title="`${
                    user.userData && user.userData.firstName
                      ? user.userData.firstName
                      : ''
                  } ${
                    user.userData && user.userData.lastName
                      ? user.userData.lastName
                      : ''
                  }`"
                  v-else
                  :src="`https://source.boringavatars.com/beam/100/${avatarNameGenerator(
                    user.userData && user.userData.firstName
                      ? user.userData.firstName
                      : '',
                    user.userData && user.userData.lastName
                      ? user.userData.lastName
                      : ''
                  )}`"
                />
              </div>
              <b-avatar
                v-if="selectedUsers.length > 10"
                ref="listBtn"
                @click.native="
                  searchView = !searchView;
                  selectedUserList = searchView;
                "
                v-b-tooltip.hover
                :title="`${selectedUsers.length - 10} more ${
                  selectedUsers.length - 10 > 1 ? 'users' : 'user'
                }`"
                class="avatar-number cursor-pointer"
                :text="`+${selectedUsers.length - 10}`"
              >
              </b-avatar>
              <main
                ref="selectedUserListContainer"
                v-if="searchView"
                class="dropdown dropdown-selectedUser mt-25"
              >
                <b-form-input
                  size="lg"
                  class="dropdown__search"
                  v-model="searchSelectedUser"
                  @focus="toggleSelectedUserListVisibility(true)"
                  placeholder="Search User"
                  autocomplete="off"
                />
                <div class="dropdown__icons">
                  <img
                    class="cursor-pointer"
                    @click="toggleSelectedUserListVisibility(!selectedUserList)"
                    src="@/assets/images/icons/user.svg"
                  />
                  <img
                    @click="toggleSelectedUserListVisibility(!selectedUserList)"
                    class="cursor-pointer"
                    src="@/assets/images/icons/arrow-drop.svg"
                  />
                </div>

                <vue-perfect-scrollbar
                  class="dropdown__content position-absolute"
                  :class="selectedUserList ? 'd-block' : 'd-none'"
                >
                  <ul
                    v-if="filteredSelectedUsers.length && selectedUserList > 0"
                    class="p-0 my-50"
                  >
                    <li
                      v-for="user in filteredSelectedUsers"
                      :key="user._id"
                      class="d-flex align-items-center justify-content-between"
                      @click="selectUser(user)"
                    >
                      <div class="d-flex align-items-center">
                        <b-avatar
                          class="mr-50"
                          v-if="user.userData.profilePictureUrl"
                          :src="`${constants.s3Bucket}/editor/profile-pictures/${user.userData.profilePictureUrl}`"
                        />
                        <b-avatar
                          class="mr-50"
                          v-else
                          :src="`https://source.boringavatars.com/beam/100/${avatarNameGenerator(
                            user.userData.firstName,
                            user.userData.lastName
                          )}`"
                        />
                        <div class="d-flex flex-column">
                          <span class="user-name">
                            {{ user.userData.firstName }}
                            {{ user.userData.lastName }}
                          </span>
                          <span>
                            {{ user.userData.email }}
                          </span>
                        </div>
                      </div>

                      <div>
                        <img
                          src="@/assets/images/icons/cross-circle.svg"
                          alt="Remove User"
                        />
                      </div>
                    </li>
                  </ul>

                  <ul
                    v-if="filteredSelectedUsers.length === 0"
                    class="p-0 my-50"
                  >
                    <div class="d-flex justify-content-center user-name px-75">
                      User not found.
                    </div>
                  </ul>
                </vue-perfect-scrollbar>
              </main>
            </div>
          </div>
          <div class="mt-25">
            <!-- <div class="heading">
              Title<span
                class="ml-25 heading--2"
                v-if="selectedItem && selectedItem.length"
                >(Optional)</span
              >
            </div>
            <b-form-input
              class="textEditor"
              type="text"
              size="lg"
              placeholder="Enter your title here"
              v-model="instructionTitle"
            /> -->
            <div class="heading">Instructions</div>
            <!-- <CKEditor class="mt-25" @editorContent="getEditorData" /> -->
            <editor ref="editor" />
          </div>

          <div class="d-flex align-items-center mt-1">
            <Checkbox
              v-b-tooltip.hover
              title="Disable email notifications when checked."
              @checkbox-checked="toggleAssignmentEmails"
            />
            <h4 class="font-weight-bolder heading__gray m-0">
              Silent Assignment
            </h4>
          </div>

          <div
            class="distribute__btn text-center py-75 mt-2"
            :class="{
              'distribute__btn--opacity': isDistributeBtnDeActive(),
            }"
            @click="distributeItems"
          >
            Distribute
          </div>

          <div class="mt-1">
            <h4
              class="font-weight-bolder heading__pink m-0 cursor-pointer"
              @click="
                $store.commit('modals/OPEN_ADD_CUSTOM_COURSE_POPUP', true);
                $store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', false);
              "
            >
              Add custom task
            </h4>
          </div>
        </section>

        <vue-perfect-scrollbar>
          <section class="right" v-if="selectedItem && selectedItem.length > 0">
            <DistributeCourse
              class="mb-1"
              v-for="(item, index) in selectedItem"
              :key="index"
              :libraryItem="item"
            />
          </section>
        </vue-perfect-scrollbar>
      </main>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BFormInput,
  BAvatar,
  VBTooltip,
  //   BFormCheckbox,
} from "bootstrap-vue";
import _ from "lodash";
import constants from "@/constants";
// import CKEditor from "@/components/common/CkEditor.vue";
import designMixin from "@/mixins/designMixin.js";
import orgMixin from "@/mixins/orgMixin.js";
import notificationMixin from "@/mixins/notificationMixin.js";
import Editor from "@/components/common/Editor.vue";
import DistributeCourse from "@/components/courses/DistributeCourse.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  name: "DistributeItem",
  components: {
    // CKEditor,
    Checkbox,
    BFormInput,
    BAvatar,
    DistributeCourse,
    VuePerfectScrollbar,
    Editor,
  },
  data() {
    return {
      constants,
      editorData: "",
      orgUsers: [],
      filteredUsers: [],
      listVisible: false,
      searchQuery: "",
      selectedUsers: [],
      searchSelectedUser: "",
      selectedUserList: false,
      filteredSelectedUsers: [],
      searchView: false,
      segments: [],
      selectedSegment: [],
      searchingUser: true,
      searchSegmentQuery: "",
      filteredSegments: [],
      instructionTitle: "",
      sendAssignmentMail: false,
      isOverLay: false,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [designMixin, orgMixin, notificationMixin],
  computed: {
    selectedItem() {
      return this.$store.state.library.selectedItem;
    },
    avatarNameGenerator() {
      return this.$store.state.user.avatarNameGenerator;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    appView() {
      return this.$store.state.user.appView;
    },
    itemInstructions() {
      return this.$store.state.library.itemInstructions;
    },
    activeOrgData() {
      return this.$store.state.user.activeOrgData;
    },
  },
  watch: {
    searchQuery() {
      this.searchUsers();
    },
    searchSegmentQuery() {
      this.searchSegments();
    },
    selectedUsers: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.filteredSelectedUsers = newVal;
      },
    },
    searchSelectedUser() {
      this.searchSelectedUsers();
    },
    appView() {
      this.getDropdownOptions();
    },
    instructionTitle() {
      this.isDistributeBtnDeActive();
    },
    itemInstructions: {
      deep: true,
      immediate: true,
      handler() {
        this.isDistributeBtnDeActive();
      },
    },
    selectedSegment: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.getSegmentedUser();
        } else {
          this.selectedUsers = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getDropdownOptions();
    this.$store.commit("library/SELECT_ITEM_INSTRUCTIONS", []);
  },
  methods: {
    isOverlayExist() {
      setTimeout(() => {
        const element = document.querySelector(".ce-popover--opened");
        if (element) {
          this.isOverLay = true;
        } else {
          this.isOverLay = false;
        }
      }, 100);
    },
    getEditorData(data) {
      this.editorData = data;
      this.isDistributeBtnDeActive();
    },
    getDropdownOptions() {
      this.appView !== "userView"
        ? this.getFilterSegments()
        : (this.segments = []);
      this.getAllOrgUsers();
    },
    getFilterSegments() {
      this.$store
        .dispatch("library/getSegments", this.orgObj)
        .then((res) => {
          for (const key in res.data) {
            if (
              res?.data[key]?.values?.length > 0 &&
              key != "currency" &&
              key != "marketProductType" &&
              res.data[key].values.some((value) => value.key !== 0)
            ) {
              let object = {
                segmentTitle: res.data[key].title,
                segmentValues: res.data[key].values,
              };
              this.segments.unshift(object);
            }
          }
          this.filteredSegments = this.segments;
        })

        .catch(() => {
          // console.log(err);
        });
    },
    getAllOrgUsers() {
      this.$store
        .dispatch("user/getOrgUsers", this.orgObj)
        .then((res) => {
          this.orgUsers = res;
          this.filteredUsers = res;

          if (res?.length > 0) {
            let userSegment = {
              segmentTitle: "Users",
              segmentValues: [],
            };
            for (let i = 0; i < this.orgUsers.length; i++) {
              const userObj = {
                key: this.orgUsers[i].userId,
                label:
                  this.orgUsers[i].userData.firstName +
                  " " +
                  this.orgUsers[i]?.userData?.lastName,
                name: "user",
                picture: this.orgUsers[i]?.userData?.profilePictureUrl,
              };
              userSegment.segmentValues.push(userObj);
            }
            this.segments.push(userSegment);
          }
        })
        .catch(() => {
          // console.log(err);
          return;
        });
    },
    toggleListVisibility(value) {
      this.listVisible = value;
      this.selectedUserList = false;
      this.searchView = false;
    },
    selectUser(user) {
      const index = this.selectedUsers.indexOf(user);
      if (index > -1) {
        this.selectedUsers.splice(index, 1);
      } else {
        this.selectedUsers.push(user);
      }
      this.toggleListVisibility(false);
    },
    selectSegment(segment) {
      if (segment.name !== "user") {
        const index = this.selectedSegment.indexOf(segment);
        if (index > -1) {
          this.selectedSegment.splice(index, 1);
        } else {
          this.selectedSegment.push(segment);
        }
      } else {
        let userObj = this.orgUsers.find((user) => user.userId === segment.key);
        let index = this.selectedUsers.findIndex((user) => {
          return user.userId === segment.key;
        });
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        } else {
          this.selectedUsers.push(userObj);
          let uniqueUsersList = this.uniqueArray(this.selectedUsers);
          this.selectedUsers = uniqueUsersList;
        }
      }
      this.toggleListVisibility(false);
      this.searchSegmentQuery = "";
    },
    getSegmentedUser() {
      this.searchingUser = true;
      const payload = {
        gtbrddOrganizationId: this.orgObj.gborgid,
        segments: this.selectedSegment,
      };
      this.$store
        .dispatch("user/getSegmentedUser", payload)
        .then((res) => {
          if (res?.data?.length > 0) {
            this.selectedUsers = [...this.selectedUsers, ...res.data];
            let uniqueUsersList = this.uniqueArray(this.selectedUsers);
            this.selectedUsers = uniqueUsersList;
          }
        })
        .catch(() => {
          return;
        })
        .finally(() => {
          this.searchingUser = false;
        });
    },
    isUserSelected(user) {
      const index = this.selectedUsers.indexOf(user);
      return index > -1 ? true : false;
    },
    isSegmentSelected(segment) {
      let index = this.selectedSegment.indexOf(segment);
      if (index === -1) {
        index = this.selectedUsers.findIndex((user) => {
          return user.userId === segment.key;
        });
      }
      return index > -1 ? true : false;
    },
    searchUsers() {
      this.filteredUsers = this.orgUsers.filter((user) => {
        const fullName = `${user.userData.firstName} ${user.userData.lastName}`;
        const email = user.userData.email;
        return (
          fullName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          email.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    searchSegments() {
      this.filteredSegments = [];
      for (const segment of this.segments) {
        if (
          segment.segmentTitle
            .toLowerCase()
            .includes(this.searchSegmentQuery.toLowerCase())
        ) {
          this.filteredSegments.push({
            segmentTitle: segment.segmentTitle,
            segmentValues: segment.segmentValues,
          });
        } else {
          const matchingValues = segment.segmentValues.filter((item) =>
            item.label
              .toLowerCase()
              .includes(this.searchSegmentQuery.toLowerCase())
          );

          if (matchingValues.length > 0) {
            this.filteredSegments.push({
              segmentTitle: segment.segmentTitle,
              segmentValues: matchingValues,
            });
          }
        }
      }
    },
    searchSelectedUsers() {
      if (this.searchSelectedUser) {
        this.filteredSelectedUsers = this.selectedUsers.filter((user) => {
          const fullName = `${user.userData.firstName} ${user.userData.lastName}`;
          const email = user.userData.email;
          return (
            fullName
              .toLowerCase()
              .includes(this.searchSelectedUser.toLowerCase()) ||
            email.toLowerCase().includes(this.searchSelectedUser.toLowerCase())
          );
        });
      } else {
        this.filteredSelectedUsers = this.selectedUsers;
      }
    },
    toggleSelectedUserListVisibility(value) {
      this.selectedUserList = value;
      this.listVisible = false;
    },
    checkClickOnContainer() {
      const listContainer = this.$refs.listContainer;
      if (listContainer && listContainer.contains(event.target)) {
        return;
      } else {
        this.listVisible = false;
      }

      if (
        this.$refs?.listBtn?.$el.contains(event.target) ||
        this.$refs?.selectedUserListContainer?.contains(event.target)
      ) {
        return;
      } else {
        this.selectedUserList = false;
        this.searchView = false;
      }
    },
    isDistributeBtnDeActive() {
      if (
        this.selectedUsers?.length === 0 ||
        (this.selectedItem?.length === 0 && this.itemInstructions?.length === 0)
      ) {
        return true;
      }
    },
    removeSelectedSegment(index) {
      this.selectedSegment.splice(index, 1);
    },
    distributeItems() {
      this.$refs.editor.saveContent();
      let userIdsSelectedForDistribution = [];
      this.selectedUsers.forEach((user) =>
        userIdsSelectedForDistribution.push(user.userId)
      );
      const libraryCourses = this.selectedItem.filter(
        (course) => !("isCustomCourse" in course)
      );

      if (libraryCourses.length > 0) {
        if (this.appView === "organizationView") {
          const payload = {
            gtbrddOrganizationId: this.orgObj.gborgid,
            segments: this.selectedSegment,
            feedLibraryItems: libraryCourses,
            assignedFromGtbrddUserId: this.orgObj.gbuid,
            instructions: this.itemInstructions,
            users: userIdsSelectedForDistribution,
            sendAssignmentMail: !this.sendAssignmentMail,
          };
          this.$store
            .dispatch("library/distributeOrgItems", payload)
            .then(() => {
              this.$store.commit("library/EMPTY_SELECTED_ITEM");
              let notificationDetail = {
                message: this.messages.success.courseDistribution(
                  this.selectedItem.length
                ),
                variant: "success",
              };
              this.showToast(notificationDetail);
            })
            .catch(() => {
              let notificationDetail = {
                message: this.messages.error.unknown_error,
                variant: "danger",
              };
              this.showToast(notificationDetail);
            })
            .finally(() => {
              this.distributeCustomItems(userIdsSelectedForDistribution);
            });
        } else {
          for (let i = 0; i < this.selectedUsers.length; i++) {
            const payload = {
              gtbrddOrganizationId: this.orgObj.gborgid,
              assignedToGtbrddUserId: this.selectedUsers[i].userId,
              assignedFromGtbrddUserId: this.orgObj.gbuid,
              feedLibraryItems: libraryCourses,
              instructions: this.itemInstructions,
              sendAssignmentMail: !this.sendAssignmentMail,
            };
            this.$store
              .dispatch("library/distributeUserItems", payload)
              .then(() => {
                this.$store.commit(
                  "modals/OPEN_DISTRIBUTE_COURSE_POPUP",
                  false
                );
                this.blockBodyScroll(false);
                this.$store.commit("library/EMPTY_SELECTED_ITEM");
                let notificationDetail = {
                  message: this.messages.success.courseDistribution(
                    this.selectedItem.length
                  ),
                  variant: "success",
                };
                this.showToast(notificationDetail);
              })
              .catch(() => {
                this.$store.commit(
                  "modals/OPEN_DISTRIBUTE_COURSE_POPUP",
                  false
                );
                this.blockBodyScroll(false);
                let notificationDetail = {
                  message: this.messages.error.unknown_error,
                  variant: "danger",
                };
                this.showToast(notificationDetail);
              });
          }
          this.distributeCustomItems(userIdsSelectedForDistribution);
        }
      } else {
        this.distributeCustomItems(userIdsSelectedForDistribution);
      }
    },
    distributeCustomItems(userIds) {
      let payload = {
        gtbrddOrganizationId: this.orgObj.gborgid,
        segments: this.selectedSegment,
        assignedFromGtbrddUserId: this.orgObj.gbuid,
        instructions: this.itemInstructions,
        users: userIds,
        sendAssignmentMail: !this.sendAssignmentMail,
      };

      if (this.selectedItem?.length === 0) {
        let customDefaultCourse = {
          title: "Assignment",
          description: this.itemInstructions,
          thumbnail: "",
          imageUrl: "",
          isCustomCourse: true,
          users: userIds,
        };
        if (this.appView === "userView") {
          customDefaultCourse = {
            ...customDefaultCourse,
            ...{
              source: `${this.userData.firstName} ${
                this.userData.lastName ? this.userData.lastName : ""
              }`,
              sourceLogoUrl: this.getUserImg(),
            },
          };
        } else {
          customDefaultCourse = {
            ...customDefaultCourse,
            ...{
              source: this.activeOrgData.name,
              sourceLogoUrl: `${constants.s3Bucket}/organization/logo/${this.activeOrgData.logo}`,
            },
          };
        }
        payload = { ...payload, ...customDefaultCourse };
        this.$store
          .dispatch("library/distributeCustomCourse", payload)
          .then(() => {})
          .catch(() => {});
      } else {
        for (let i = 0; i < this.selectedItem.length; i++) {
          if (this.selectedItem[i].isCustomCourse) {
            payload = { ...payload, ...this.selectedItem[i] };
            this.$store
              .dispatch("library/distributeCustomCourse", payload)
              .then(() => {})
              .catch(() => {});
          }
        }
      }
      this.$store.commit("modals/OPEN_DISTRIBUTE_COURSE_POPUP", false);
      this.blockBodyScroll(false);
    },
    uniqueArray(users) {
      const uniqueMap = _.keyBy(users, "_id");
      return _.values(uniqueMap);
    },
    toggleAssignmentEmails(val) {
      this.sendAssignmentMail = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select-lg:focus,
.form-control:focus,
.input-group-lg::v-deep input:focus {
  border-color: var(--org-theme-color) !important;
}

.distribute-item {
  max-width: 90%;
  max-height: 90vh;
  // min-height: 90%;
  height: max-content;
  border-radius: 10px;
  background: #ffffff;
  padding: 2.5rem;
  padding-top: 0;
  &__background {
    background: #0000006a;
    backdrop-filter: blur(5px);
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
  }
  .heading {
    &__black {
      font-size: 2.25rem;
      font-weight: 700 !important;
    }
    &__gray {
      color: #787878;
      font-size: 1.25rem;
      font-weight: 400 !important;
    }
    &__pink {
      color: #ff6584;
      font-size: 1.25rem;
      font-weight: 400 !important;
    }
  }
  .cross-icon {
    background: #e6e6e6;
    color: #98a2b3;
    font-size: 1.25em;
    font-weight: 900;
    padding: 0.5em;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    transition: all 0.23s ease 0.1s;
    font-family: "Montserrat", Helvetica, Arial, serif;
  }
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-top: 1rem;
    .left {
      min-width: 30rem;
      width: 100%;
      max-width: 35rem;
      &.isRight {
        width: 35%;
      }
      .heading {
        font-size: 1.5rem;
        font-weight: 600 !important;
        color: #000;
        margin-top: 1rem;
        &--2 {
          color: #98a2b3;
          font-weight: 400;
        }
      }
      .users__selected {
        border-top: 2px solid #f2f4f7;
        .tags__container {
          margin: 0 -0.5rem;
        }
        .text {
          color: #948f8f;
          font-size: 1.15rem;
        }
        .avatar {
          &__list {
            .--avatar {
              border: 2px solid #fff;
              &:hover {
                z-index: 1;
                scale: 1.2;
              }
            }
            &:nth-child(n + 2) {
              margin-left: -1.25rem;
            }
          }
          &-number {
            margin-left: -1.25rem;
            background-color: #e7e7e7;
            border: 2px solid #fff;
            color: #000712;
            &:hover {
              z-index: 1;
              scale: 1.2;
            }
          }
        }
        .dropdown-selectedUser {
          position: absolute;
          top: 2.5rem;
          z-index: 2;
          left: 3rem;
          width: max-content;
          min-width: 20rem;
        }
      }
      .distribute__btn {
        background: #ff6584;
        cursor: pointer;
        box-shadow: 0px 0px 14px 0px rgba(255, 101, 132, 0.1);
        color: #fff;
        border-radius: 0.35rem;
        font-size: 1.25rem;
        font-weight: 600;
        &--opacity {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    .right {
      min-width: 30rem;
      max-width: 45rem;
      max-height: calc(0.75 * 90vh);
    }
  }
}

.fixed-container {
  background: #fff;
  position: sticky;
  padding-bottom: 0.5rem;
  padding-top: 2.5rem;
  z-index: 15;
  top: 0;
}
@media only screen and (max-width: 1200px) {
  .distribute-item {
    .heading {
      &__black {
        font-size: 2rem;
      }
    }
    &__container {
      margin-top: 0rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .distribute-item {
    padding: 1.5rem;
    padding-top: 0;
    &__container {
      flex-direction: column;
      .left {
        min-width: 100%;
        max-width: 100%;
      }
      .right {
        min-width: 100%;
        max-width: 100%;
        max-height: max-content;
      }
    }
  }

  .fixed-container {
    padding-top: 1.5rem;
  }
}

@media only screen and (max-width: 760px) {
  .distribute-item {
    width: calc(80% - 20px);
    .heading {
      &__black {
        font-size: 1.5rem;
      }
      &__gray {
        font-size: 1rem;
      }
      &__pink {
        font-size: 1rem;
      }
    }

    .cross-icon {
      font-size: 1.25em;
      height: 1.75em;
      width: 1.75em;
    }

    &__container {
      .left {
        .heading {
          margin-top: 0.25rem;
          font-size: 1.25rem;
        }
      }
    }
  }
}

input.textEditor {
  // background-color:red;
  width: 100%;
  outline: none;
  // border: 1px solid blue;
  border-radius: 4px;
  padding-left: 1rem;
  margin: 0.5rem 0px;
}
</style>
