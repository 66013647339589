<template>
  <div class="checkbox-wrapper">
    <label class="checkbox mb-0">
      <input
        class="checkbox__trigger visuallyhidden"
        v-model="isChecked"
        :value="value"
        @change="handleChange"
        type="checkbox"
      />
      <span class="checkbox__symbol">
        <svg
          aria-hidden="true"
          class="icon-checkbox"
          width="28px"
          height="28px"
          viewBox="0 0 28 28"
          version="1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 14l8 7L24 7"></path>
        </svg>
      </span>
      <p class="checkbox__textwrapper">{{ label }}</p>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxWhite",
  data() {
    return {
      isChecked: false,
    };
  },
  props: {
    label: {
      type: String,
    },
    value: {},
  },
  computed: {
    selectedItem() {
      return this.$store.state.library.selectedItem;
    },
  },
  watch: {
    selectedItem: {
      handler(newVal) {
        newVal && newVal.some((item) => item.itemId === this.value?.itemId)
          ? this.isChecked
          : (this.isChecked = false);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleChange() {
      this.$emit("checkbox-value", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/checkbox.scss";
</style>
