var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 md-pd d-flex align-items-center w-100 h-100 justify-content-center distribute-item__background",on:{"click":function($event){_vm.$store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', false);
    _vm.$store.commit('library/SELECT_ITEM_INSTRUCTIONS', []);
    _vm.blockBodyScroll(false);},"!click":function($event){return _vm.isOverlayExist.apply(null, arguments)}}},[_c('vue-perfect-scrollbar',{staticClass:"w-60 distribute-item position-relative",on:{"click":function($event){$event.stopPropagation();return _vm.checkClickOnContainer.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex align-items-start justify-content-between fixed-container",style:(_vm.isOverLay ? 'z-index:1' : '')},[_c('div',[_c('h2',{staticClass:"font-weight-bolder heading__black"},[_vm._v("Distribute Tasks")]),_c('h4',{staticClass:"font-weight-bolder heading__gray"},[_vm._v(" Distribute tasks to your trainee and colleagues ")])]),_c('div',{staticClass:"cross-icon d-flex align-items-center justify-content-center cursor-pointer",on:{"click":function($event){_vm.$store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', false);
          _vm.blockBodyScroll(false);}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/cross-icon.svg"),"alt":""}})])]),_c('main',{staticClass:"distribute-item__container",style:({ gap: _vm.selectedItem && _vm.selectedItem.length > 0 ? '2rem' : '0' })},[_c('section',{staticClass:"left",class:{ isRight: _vm.selectedItem && _vm.selectedItem.length > 0 }},[_c('div',[_c('div',{staticClass:"heading"},[_vm._v("Distribute to")]),(_vm.appView === 'userView')?_c('main',{ref:"listContainer",staticClass:"dropdown w-100 mt-25"},[_c('b-form-input',{staticClass:"dropdown__search",attrs:{"size":"lg","placeholder":"Search User","autocomplete":"off"},on:{"focus":function($event){return _vm.toggleListVisibility(true)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('div',{staticClass:"dropdown__icons"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/icons/user.svg"),"alt":""},on:{"click":function($event){return _vm.toggleListVisibility(!_vm.listVisible)}}}),_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/icons/arrow-drop.svg"),"alt":""},on:{"click":function($event){return _vm.toggleListVisibility(!_vm.listVisible)}}})]),_c('vue-perfect-scrollbar',{staticClass:"dropdown__content position-absolute",class:_vm.listVisible ? 'd-block' : 'd-none'},[(_vm.filteredUsers.length > 0)?_c('ul',{staticClass:"p-0 my-50"},_vm._l((_vm.filteredUsers),function(user){return _c('li',{key:user._id,staticClass:"align-items-center",class:{
                    option__selected: _vm.isUserSelected(user),
                    'd-none': _vm.userData._id === user.userId,
                    'd-flex': _vm.userData._id !== user.userId,
                  },on:{"click":function($event){return _vm.selectUser(user)}}},[(user.userData.profilePictureUrl)?_c('b-avatar',{staticClass:"mr-50",attrs:{"src":((_vm.constants.s3Bucket) + "/editor/profile-pictures/" + (user.userData.profilePictureUrl))}}):_c('b-avatar',{staticClass:"mr-50",attrs:{"src":("https://source.boringavatars.com/beam/100/" + (_vm.avatarNameGenerator(
                      user.userData.firstName,
                      user.userData.lastName
                    )))}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"user-name"},[_vm._v(" "+_vm._s(user.userData.firstName)+" "+_vm._s(user.userData.lastName)+" ")]),_c('span',[_vm._v(" "+_vm._s(user.userData.email)+" ")])])],1)}),0):_vm._e(),(_vm.filteredUsers.length === 0)?_c('ul',{staticClass:"p-0 my-50"},[_c('div',{staticClass:"d-flex justify-content-center user-name px-75"},[_vm._v(" User not found. ")])]):_vm._e()])],1):_c('main',{ref:"listContainer",staticClass:"dropdown w-100 mt-25"},[_c('b-form-input',{staticClass:"dropdown__search cursor-pointer",staticStyle:{"background-color":"white"},attrs:{"size":"lg","placeholder":"Search Segment","autocomplete":"off"},on:{"focus":function($event){return _vm.toggleListVisibility(true)}},model:{value:(_vm.searchSegmentQuery),callback:function ($$v) {_vm.searchSegmentQuery=$$v},expression:"searchSegmentQuery"}}),_c('div',{staticClass:"dropdown__icons"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/icons/arrow-drop.svg"),"alt":""},on:{"click":function($event){return _vm.toggleListVisibility(!_vm.listVisible)}}})]),_c('vue-perfect-scrollbar',{staticClass:"dropdown__content --org position-absolute",class:_vm.listVisible ? 'd-block' : 'd-none'},[(_vm.filteredSegments.length > 0)?_c('div',{staticClass:"p-0 my-50"},_vm._l((_vm.filteredSegments),function(segment,index){return _c('div',{key:index,staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"segment__container w-100"},[_c('h5',{staticClass:"m-0 title"},[_vm._v(" "+_vm._s(segment.segmentTitle)+" ")]),_vm._l((segment.segmentValues),function(value,index){return _c('div',{key:index,staticClass:"value",class:{
                        value__selected: _vm.isSegmentSelected(value),
                      },on:{"click":function($event){return _vm.selectSegment(value)}}},[(value.name === 'user')?_c('span',[(value.picture)?_c('b-avatar',{staticClass:"mr-50",attrs:{"src":((_vm.constants.s3Bucket) + "/editor/profile-pictures/" + (value.picture))}}):_c('b-avatar',{staticClass:"mr-50",attrs:{"src":("https://source.boringavatars.com/beam/100/" + (_vm.avatarNameGenerator(
                            value.label
                          )))}})],1):_vm._e(),_vm._v(" "+_vm._s(value.label)+" ")])})],2)])}),0):_vm._e(),(_vm.filteredSegments.length === 0)?_c('ul',{staticClass:"p-0 my-50"},[_c('div',{staticClass:"d-flex justify-content-center user-name px-75"},[_vm._v(" Segment not found. ")])]):_vm._e()])],1)]),(_vm.selectedUsers.length || _vm.selectedSegment.length > 0)?_c('div',{staticClass:"users__selected mt-1"},[(_vm.selectedSegment.length > 0)?_c('div',[_c('h4',{staticClass:"text mt-75 mb-25"},[_vm._v("Tags Selected")]),_c('div',{staticClass:"d-flex flex-wrap tags__container"},_vm._l((_vm.selectedSegment),function(segment,index){return _c('div',{key:index,staticClass:"pill pill-filter m-25 d-flex align-items-center justify-items-center"},[_vm._v(" "+_vm._s(segment.label)+" "),_c('div',{staticClass:"cross align-items-center justify-content-center cursor-pointer",on:{"click":function($event){return _vm.removeSelectedSegment(index)}}},[_vm._v(" X ")])])}),0)]):_vm._e(),(
              !_vm.searchingUser &&
              _vm.selectedSegment.length > 0 &&
              _vm.selectedUsers.length === 0
            )?_c('h4',{staticClass:"text mt-25"},[_vm._v(" No user found ")]):_vm._e(),(_vm.selectedUsers.length > 0)?_c('h4',{staticClass:"text mt-75"},[_vm._v(" "+_vm._s(_vm.selectedUsers.length)+" "+_vm._s(_vm.selectedUsers.length > 1 ? "users" : "user")+" selected "),(_vm.selectedItem && _vm.selectedItem.length)?_c('span',[_vm._v(" for "+_vm._s(_vm.selectedItem && _vm.selectedItem.length)+" course distribution ")]):_vm._e()]):_vm._e(),(_vm.selectedUsers.length > 0)?_c('div',{staticClass:"d-flex align-items-center position-relative"},[_vm._l((_vm.selectedUsers.slice(0, 10)),function(user){return _c('div',{key:user._id,staticClass:"avatar__list"},[(user.userData && user.userData.profilePictureUrl)?_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer --avatar",attrs:{"src":((_vm.constants.s3Bucket) + "/editor/profile-pictures/" + (user.userData.profilePictureUrl)),"title":((user.userData && user.userData.firstName
                    ? user.userData.firstName
                    : '') + " " + (user.userData && user.userData.lastName
                    ? user.userData.lastName
                    : ''))}}):_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer --avatar",attrs:{"title":((user.userData && user.userData.firstName
                    ? user.userData.firstName
                    : '') + " " + (user.userData && user.userData.lastName
                    ? user.userData.lastName
                    : '')),"src":("https://source.boringavatars.com/beam/100/" + (_vm.avatarNameGenerator(
                  user.userData && user.userData.firstName
                    ? user.userData.firstName
                    : '',
                  user.userData && user.userData.lastName
                    ? user.userData.lastName
                    : ''
                )))}})],1)}),(_vm.selectedUsers.length > 10)?_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],ref:"listBtn",staticClass:"avatar-number cursor-pointer",attrs:{"title":((_vm.selectedUsers.length - 10) + " more " + (_vm.selectedUsers.length - 10 > 1 ? 'users' : 'user')),"text":("+" + (_vm.selectedUsers.length - 10))},nativeOn:{"click":function($event){_vm.searchView = !_vm.searchView;
                _vm.selectedUserList = _vm.searchView;}}}):_vm._e(),(_vm.searchView)?_c('main',{ref:"selectedUserListContainer",staticClass:"dropdown dropdown-selectedUser mt-25"},[_c('b-form-input',{staticClass:"dropdown__search",attrs:{"size":"lg","placeholder":"Search User","autocomplete":"off"},on:{"focus":function($event){return _vm.toggleSelectedUserListVisibility(true)}},model:{value:(_vm.searchSelectedUser),callback:function ($$v) {_vm.searchSelectedUser=$$v},expression:"searchSelectedUser"}}),_c('div',{staticClass:"dropdown__icons"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/icons/user.svg")},on:{"click":function($event){return _vm.toggleSelectedUserListVisibility(!_vm.selectedUserList)}}}),_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/icons/arrow-drop.svg")},on:{"click":function($event){return _vm.toggleSelectedUserListVisibility(!_vm.selectedUserList)}}})]),_c('vue-perfect-scrollbar',{staticClass:"dropdown__content position-absolute",class:_vm.selectedUserList ? 'd-block' : 'd-none'},[(_vm.filteredSelectedUsers.length && _vm.selectedUserList > 0)?_c('ul',{staticClass:"p-0 my-50"},_vm._l((_vm.filteredSelectedUsers),function(user){return _c('li',{key:user._id,staticClass:"d-flex align-items-center justify-content-between",on:{"click":function($event){return _vm.selectUser(user)}}},[_c('div',{staticClass:"d-flex align-items-center"},[(user.userData.profilePictureUrl)?_c('b-avatar',{staticClass:"mr-50",attrs:{"src":((_vm.constants.s3Bucket) + "/editor/profile-pictures/" + (user.userData.profilePictureUrl))}}):_c('b-avatar',{staticClass:"mr-50",attrs:{"src":("https://source.boringavatars.com/beam/100/" + (_vm.avatarNameGenerator(
                          user.userData.firstName,
                          user.userData.lastName
                        )))}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"user-name"},[_vm._v(" "+_vm._s(user.userData.firstName)+" "+_vm._s(user.userData.lastName)+" ")]),_c('span',[_vm._v(" "+_vm._s(user.userData.email)+" ")])])],1),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/icons/cross-circle.svg"),"alt":"Remove User"}})])])}),0):_vm._e(),(_vm.filteredSelectedUsers.length === 0)?_c('ul',{staticClass:"p-0 my-50"},[_c('div',{staticClass:"d-flex justify-content-center user-name px-75"},[_vm._v(" User not found. ")])]):_vm._e()])],1):_vm._e()],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-25"},[_c('div',{staticClass:"heading"},[_vm._v("Instructions")]),_c('editor',{ref:"editor"})],1),_c('div',{staticClass:"d-flex align-items-center mt-1"},[_c('Checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Disable email notifications when checked."},on:{"checkbox-checked":_vm.toggleAssignmentEmails}}),_c('h4',{staticClass:"font-weight-bolder heading__gray m-0"},[_vm._v(" Silent Assignment ")])],1),_c('div',{staticClass:"distribute__btn text-center py-75 mt-2",class:{
            'distribute__btn--opacity': _vm.isDistributeBtnDeActive(),
          },on:{"click":_vm.distributeItems}},[_vm._v(" Distribute ")]),_c('div',{staticClass:"mt-1"},[_c('h4',{staticClass:"font-weight-bolder heading__pink m-0 cursor-pointer",on:{"click":function($event){_vm.$store.commit('modals/OPEN_ADD_CUSTOM_COURSE_POPUP', true);
              _vm.$store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', false);}}},[_vm._v(" Add custom task ")])])]),_c('vue-perfect-scrollbar',[(_vm.selectedItem && _vm.selectedItem.length > 0)?_c('section',{staticClass:"right"},_vm._l((_vm.selectedItem),function(item,index){return _c('DistributeCourse',{key:index,staticClass:"mb-1",attrs:{"libraryItem":item}})}),1):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }