var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event__card__container cursor-pointer",class:{
    event__card__container__highlight:
      _vm.itemId === _vm.event.itemId ||
      (_vm.selectedItem &&
        _vm.selectedItem.some(function (item) { return item.itemId === _vm.event.itemId; })),
  },on:{"mouseenter":function($event){_vm.itemId = _vm.event.itemId},"mouseleave":function($event){_vm.itemId = ''}}},[_c('div',{staticClass:"item__card__checkbox",class:{
      item__card__checkbox__highlight:
        _vm.itemId === _vm.event.itemId ||
        (_vm.selectedItem &&
          _vm.selectedItem.some(function (item) { return item.itemId === _vm.event.itemId; })),
    }},[_c('CheckboxWhite',{attrs:{"value":_vm.event},on:{"checkbox-value":_vm.getSelectedValue}})],1),_c('div',{staticClass:"img__container"},[_c('img',{attrs:{"src":_vm.event.imageUrl ? _vm.event.imageUrl : _vm.randomFeedImage},on:{"click":function($event){return _vm.redirectPage(_vm.event)},"error":function($event){return _vm.randomFeedImage(_vm.event, _vm.event.itemId)}}})]),_c('div',{staticClass:"content__container",on:{"click":function($event){return _vm.redirectPage(_vm.event)}}},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.event.title)+" ")]),_c('div',{staticClass:"detail d-flex align-items-center"},[_c('img',{staticClass:"mr-1 img",attrs:{"src":require("@/assets/images/icons/calender.svg"),"alt":"Calender"}}),_c('span',[_c('span',{staticClass:"main"},[_vm._v(_vm._s(_vm.event.startDate))])])]),_c('div',{staticClass:"detail"},[_c('img',{staticClass:"mr-1 img",attrs:{"src":require("@/assets/images/icons/location.svg"),"alt":"Calender"}}),_c('span',[_c('h4',{staticClass:"main"},[_vm._v(_vm._s(_vm.event.location))])])]),_c('div',{staticClass:"detail"},[_c('img',{staticClass:"mr-1 img",attrs:{"src":_vm.event.sourceLogoUrl},on:{"error":function($event){_vm.event.target.src = 'https://source.boringavatars.com/ring'}}}),_c('span',[_c('h4',{staticClass:"main"},[_vm._v(_vm._s(_vm.event.organizer))]),_c('h5',{staticClass:"m-0 sub"},[_vm._v("Organizer")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }