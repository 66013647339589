<template>
  <div class="popup__filter__content">
    <div class="popup__filter__tabs">
      <div class="vertical-tabs">
        <div class="tab-container">
          <div
            v-for="(section, index) in sections"
            :key="index"
            @click="changeTab(index)"
            :class="{ active: activeTab === index }"
            class="tab"
          >
            {{ section.charAt(0).toUpperCase() + section.slice(1) }}
          </div>
          <!-- Duration Tab -->
          <!-- <div
            class="tab"
            @click="changeTab(sections.length)"
            :class="{ active: activeTab === sections.length }"
          >
            Duration
          </div> -->
          <div class="indicator" :style="{ top: `${activeTab * 3}rem` }"></div>
        </div>
        <div class="tab-content">
          <vue-perfect-scrollbar
            v-for="(group, tabName) in groupedData"
            :key="tabName"
            :class="{ 'content-show': sections[activeTab] === tabName }"
            class="content scrollable"
          >
            <div
              class="popup__filter__checkbox"
              v-for="filter in group"
              :key="filter.value"
            >
              <Checkbox
                :isSelected="filter.checked"
                @checkbox-checked="handleCheckboxChange(filter)"
              />
              {{ filter.label }}
            </div>
          </vue-perfect-scrollbar>
          <!-- Duration Content -->
          <!-- <div
            :class="{ 'content-show': sections[activeTab] === tabName }"
            class="content"
          >
            <p class="">Duration 0 - {{ durationValue }} Minutes</p>
            <input
              :style="gradientStyle"
              id="duration"
              type="range"
              min="0"
              max="25"
              v-model="durationValue"
            />
          </div> -->
        </div>
      </div>
    </div>
    <div
      v-if="
        selectedCheckboxes.length > 0 ||
        selectedFilter.length > 0 ||
        removedSelections.length > 0
      "
      class="popup__filter__apply"
    >
      <button
        @click="clearFilters"
        class="clear"
        :disabled="
          selectedCheckboxes.length === 0 && selectedFilter.length === 0
        "
      >
        Clear Filters
      </button>
      <button @click="applyChanges">Apply</button>
    </div>
  </div>
</template>

<script>
import Checkbox from "../common/Checkbox.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  languageTypes,
  learningFormatTypes,
  learningSkillTypes,
} from "@/assets/data/alexFilterOptions";
export default {
  components: {
    Checkbox,
    VuePerfectScrollbar,
  },
  data() {
    return {
      checkBoxes: {},
      appliedFilters: [],
      groupedCheckboxes: {},
      dataItems: [],
      selectedCheckboxes: [],
      activeTab: 0,
      isVisible: true,
      languageTypes,
      learningFormatTypes,
      learningSkillTypes,
      durationValue: 25,
      removedSelections: [],
    };
  },
  props: {
    itemSources: {
      required: true,
      type: Array,
    },
  },
  computed: {
    groupedData() {
      return this.dataItems.reduce((result, item) => {
        const key = item.key;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(item);
        return result;
      }, {});
    },
    sections() {
      return Object.keys(this.groupedData);
    },
    gradientStyle() {
      const percentage = this.durationValue * 4 + "%";
      const gradient = `linear-gradient(to right, rgb(255,101,132) 0%, rgb(255,101,132) ${percentage}, rgb(221,221,221) ${percentage}, rgb(221,221,221) 100%)`;
      return {
        background: gradient,
      };
    },
    selectedFilter() {
      return this.$store.state.library.selectedFilter;
    },
  },
  mounted() {
    this.getFilters();
    this.restoreState();
    // this.selectedCheckboxes = this.selectedFilter;
  },
  methods: {
    getFilters() {
      this.dataItems = languageTypes
        .concat(learningFormatTypes, learningSkillTypes,this.itemSources)
        .map((obj) => [obj])
        .flat();
    },
    uploadFilters(filters){
      this.$store.commit("library/SELECT_FILTERS", filters);
    },
    restoreState(){
      const selectedFilters = this.selectedFilter;
      for (const tabName in this.groupedData) {
        if (Object.hasOwnProperty.call(this.groupedData, tabName)) {
          const group = this.groupedData[tabName];
          for (const filter of group) {
            const selectedFilter = selectedFilters.find(
              (item) =>
                item.key === filter.key &&
                item.value === filter.value &&
                item.label === filter.label
            );
            if (selectedFilter) {
              filter.checked = true;
            } else {
              filter.checked = false;
            }
          }
        }
      }
    },
    changeTab(index) {
      this.activeTab = index;
    },
    handleCheckboxChange(filter) {
      filter.checked = !filter.checked;
      if (filter.checked) {
        this.selectedCheckboxes.push({
          value: filter.value,
          key: filter.key,
          label: filter.label,
          checked: filter.checked,
        });
        const index = this.removedSelections.findIndex(
          (item) => item.key === filter.key && item.value === filter.value
        );
        if (index !== -1) {
          this.removedSelections.splice(index, 1);
        }
      } else {
        this.removedSelections.push({
          value: filter.value,
          key: filter.key,
          label: filter.label,
          checked: filter.checked,
        });
        let index = this.selectedCheckboxes.findIndex(
          (item) => item.key === filter.key && item.value === filter.value
        );
        if (index !== -1) {
          this.selectedCheckboxes.splice(index, 1);
        }
        // index = this.selectedFilter.findIndex((item)=>item.key === filter.key && item.value === filter.value);
        // if (index !== -1){
        //   this.selectedFilter.splice(index,1)
        // }
      }
    },
    applyChanges() {
      const mergedArray = this.selectedCheckboxes
      .concat(this.selectedFilter)
      // .filter(item => !this.removedSelections.includes(item));
      const valuesToRemove = new Set(this.removedSelections.map(obj => obj.value));
      const filteredArray = mergedArray.filter(obj => !valuesToRemove.has(obj.value));
      // this.$store.commit("library/SELECT_FILTERS", mergedArray);
      this.uploadFilters(filteredArray);
      // this.$store.commit("library/SELECT_FILTERS", this.selectedCheckboxes);
      this.$store.commit("modals/OPEN_FILTER_POPUP", false);
    },
    clearFilters() {
      for (const groupKey in this.groupedData) {
        if (Object.hasOwnProperty.call(this.groupedData, groupKey)) {
          const group = this.groupedData[groupKey];
          for (const filter of group) {
            if (filter.checked === true) {
              this.handleCheckboxChange(filter);
            }
          }
        }
      }
      this.selectedCheckboxes = [];
      this.$store.commit("library/SELECT_FILTERS", this.selectedCheckboxes);
      // this.$store.commit("modals/OPEN_FILTER_POPUP", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__filter {
  &__content {
    width: 100%;
    display: flex;
  }
  &__tabs {
    border-top: 1px solid #f2f4f7;
    flex: 1;
    height: 100%;
  }
  &__checkbox {
    display: flex;
    padding-bottom: 0.5em;
    align-items: center;
  }
  &__apply {
    display: flex;
    background-color: white;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 0 0 1rem 1rem;
    position: absolute;
    bottom: 0;
    right: 0;
    justify-content: end;
    gap: 1em;
    padding: 1em;
    button {
      background-color: rgb(255, 101, 132);
      font-size: medium;
      border: 0px;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      color: white;
      max-width: fit-content;
      &.clear {
        color: rgb(255, 101, 132);
        background-color: white;
        &:disabled {
          color: #f2f4f7;
        }
      }
    }
  }
}

.vertical-tabs {
  height: 100%;
  display: flex;
  flex: 1;
  .tab-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 15em;
    border-right: 1px solid #f2f4f7;
  }

  .tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1em;
    transition: background-color 0.2s;
    user-select: none;
    height: 3rem;
    font-size: medium;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    &.active {
      color: black;
    }
  }

  .indicator {
    position: absolute;
    width: 0.35rem;
    height: 3rem;
    border-radius: 0px 5px 5px 0px;
    background-color: rgb(255, 101, 132);
    transition: top 0.2s;
  }

  .tab-content {
    width: 100%;
    max-height: 100%;
    font-family: "Poppins", sans-serif;
    font-size: medium;
  }

  .content {
    display: none;
    padding: 20px;
    margin: 0px;
    height: 100%;

    &-show {
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
  height: 4px;
  cursor: pointer;
  outline: 0;
  border-radius: 1em;
  background: linear-gradient(
    to right,
    rgb(255, 101, 132) 0%,
    rgb(255, 101, 132) 0%,
    rgb(221, 221, 221) 0%,
    rgb(221, 221, 221) 100%
  );
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    background-color: rgb(255, 101, 132);
    border-radius: 50%;
  }
}

.content.scrollable {
  // overflow-y: auto;
  overflow: hidden;
  max-height: 16rem;
}
</style>
