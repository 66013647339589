<template>
  <div id="searchAnchor" class="d-flex justify-content-center w-100">
    <div
      class="library__container w-100"
      :style="{ 'max-width': '1240px', width: '100%' }"
    >
      <div
        class="mb-3 d-flex flex-wrap --gap justify-content-center"
        v-if="isSkeleton"
      >
        <div v-for="i in 12" :key="i + 'skeleton'">
          <course-skeleton />
        </div>
      </div>

      <div
        class="w-100 d-flex flex-wrap justify-content-center flex-row --gap"
        :class="{
          '--gap': libraryItems && libraryItems.length !== 0,
          '--gap__x':
            events &&
            events.length === 0 &&
            !searchItemQuery &&
            selectedFilter.length === 0,
        }"
        v-if="!isSkeleton"
      >
        <div class="d-flex justify-content-between w-100">
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            :style="{ 'max-width': '1240px' }"
            v-if="
              events &&
              events.length !== 0 &&
              !searchItemQuery &&
              selectedFilter.length === 0
            "
          >
            <h6
              class="w-100"
              style="font-size: 2em !important; font-weight: 600; color: black"
            >
              For You
            </h6>
          </div>
          <!-- Filters  -->
          <div
            class="d-flex align-items-center w-100"
            :class="
              selectedFilter.length > 0
                ? 'justify-content-between'
                : 'justify-content-end'
            "
          >
            <!-- <div class="d-flex align-items-center">
            <div class="pill pill-filter d-flex align-items-center mr-1" v-for="filter in selectedFilter"
              :key="filter.value">
              {{ filter.label }}
              <div @click="removeFilter(filter)" class="cross align-items-center justify-content-center cursor-pointer">
                X
              </div>
            </div>
          </div> -->
            <div class="d-flex align-items-center justify-content-end w-100">
              <img
                @click="
                  $store.commit('modals/OPEN_SEARCH_POPUP', !searchPopup);
                  $store.commit('library/UPDATE_SEARCH_ITEM_QUERY', '');
                  blockBodyScroll(true);
                "
                class="btn filter"
                src="@/assets/images/icons/search.svg"
                alt="search"
              />

              <img
                @click="
                  $store.commit('modals/OPEN_FILTER_POPUP', !filterPopup);
                  blockBodyScroll(true);
                "
                class="btn ml-50 ml-md-75"
                src="@/assets/images/icons/filter.svg"
                alt="filter"
                v-if="
                  (libraryItems && libraryItems.length !== 0) ||
                  (events && events.length !== 0) ||
                  searchItemQuery ||
                  selectedFilter.length > 0
                "
              />
              <button
                class="btn ml-50 ml-md-75 distribute"
                @click="
                  $store.commit('modals/OPEN_DISTRIBUTE_COURSE_POPUP', true);
                  blockBodyScroll(true);
                "
              >
                Distribute
              </button>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-start w-100 flex-wrap" style="gap: 1rem">
          <div
            class="pill pill-filter d-flex align-items-center"
            v-for="filter in selectedFilter"
            :key="filter.value"
          >
            {{ filter.label }}
            <div
              @click="removeFilter(filter)"
              class="cross align-items-center justify-content-center cursor-pointer"
            >
              X
            </div>
          </div>
        </div>
        <!--Event sliders Section -->
        <EventSliders
          v-if="
            events &&
            events.length > 0 &&
            !searchItemQuery &&
            selectedFilter &&
            selectedFilter.length === 0
          "
          :events="events"
        />

        <div class="d-flex flex-column align-items-center w-100">
          <!-- Courses  -->
          <div
            class="flex w-100 mt-25 mt-lg-2"
            :style="{ 'max-width': '1240px' }"
            v-if="
              libraryItems &&
              libraryItems.length > 0 &&
              !searchItemQuery &&
              selectedFilter &&
              selectedFilter.length === 0
            "
          >
            <h6
              class=""
              style="font-size: 2em !important; font-weight: 600; color: black"
            >
              Explore
            </h6>

            <hr />
          </div>

          <div :style="{ 'max-width': '1240px' }" class="mt-50 flex-wrap --gap">
            <div
              v-show="libraryItems && libraryItems.length > 0"
              v-for="(libraryItem, index) in libraryItems"
              :key="index"
            >
              <EventCard
                v-if="
                  (libraryItem &&
                    (libraryItem.itemType || libraryItem.data.itemType)) ===
                  'event'
                "
                :event="libraryItem.data || libraryItem"
              />
              <Courses v-else :libraryItem="libraryItem.data || libraryItem" />
            </div>
          </div>

          <div
            v-if="
              (!libraryItems || (libraryItems && libraryItems.length === 0)) &&
              (!events || (events && events.length === 0))
            "
            :style="{ 'max-width': '1240px' }"
            class="d-flex flex-column align-items-center justify-content-center w-100 empty__library"
          >
            <img
              style="scale:1"
              src="@/assets/images/library/empty-development.svg"
              alt="Empty Items"
            />
            <h2
              class="heading__black empty__library__text mt-50 mb-50 mb-md-3"
              v-if="selectedFilter && selectedFilter.length > 0"
            >
              Alas, no results to display. Why not to try a different filter ?
            </h2>

            <h2
              class="heading__black empty__library__text mt-50 mb-50 mb-md-3"
              v-else-if="searchItemQuery"
            >
              No match found for "{{ searchItemQuery }}".Why not refine your
              search criteria ?
            </h2>

            <h2
              class="heading__black empty__library__text mt-50 mb-50 mb-md-3"
              v-else
            >
              Your library is being curated!
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventCard from "@/components/courses/EventCard.vue";
import Courses from "@/components/courses/Courses.vue";
import CourseSkeleton from "@/components/courses/CourseSkeleton.vue";
import designMixin from "@/mixins/designMixin.js";
import orgMixin from "@/mixins/orgMixin.js";
import EventSliders from "@/components/events/EventsSlider.vue";
export default {
  components: {
    EventCard,
    Courses,
    CourseSkeleton,
    EventSliders,
  },
  data() {
    return {
      libraryItems: [],
      mixedLibraryItems: [],
      isSkeleton: true,
      mixedEvents: [],
      events: [],
      searchingLibrary: false,
    };
  },
  mixins: [orgMixin, designMixin],
  mounted() {
    this.getLibraryItems();
  },
  computed: {
    filterPopup() {
      return this.$store.state.modals.filterPopup;
    },
    searchPopup() {
      return this.$store.state.modals.searchPopup;
    },
    searchItemQuery() {
      return this.$store.state.library.searchItemQuery;
    },
    selectedFilter() {
      return this.$store.state.library.selectedFilter;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    appView() {
      return this.$store.state.user.appView;
    },
  },
  watch: {
    searchItemQuery: {
      handler(newVal) {
        if (newVal) {
          this.searchCourse(newVal);
          this.$store.commit("library/SELECT_FILTERS", []);
          this.searchingLibrary = true;
        } else {
          this.searchingLibrary = false;
          this.getLibraryItems();
          // if (this.mixedLibraryItems.length === 0) {
          // }
          // this.libraryItems = this.mixedLibraryItems;
          // this.events = this.mixedEvents;
        }
      },
    },
    selectedFilter: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.mixedLibraryItems = [
            ...this.mixedLibraryItems,
            ...this.mixedEvents,
          ];
          // this.mixedEvents = [];
          // this.events = this.mixedEvents;

          const groupedFilter = newVal.reduce((selectedItem, item) => {
            if (!selectedItem[item.key]) {
              selectedItem[item.key] = [];
            }
            item.value === "PARTICIPATE"
              ? selectedItem[item.key].push("PARTICPATE")
              : "";
            selectedItem[item.key].push(
              item.value.replace(/[-\s]/g, "").toLowerCase()
            );
            return selectedItem;
          }, {});

          const filteredFeedData = this.mixedLibraryItems.filter((item) => {
            // check if item satisfies all selected filters
            for (let key in groupedFilter) {
              const selectedFilterOptions = groupedFilter[key];
              const itemValue =
                item && item.data && item.data[key]
                  ? item.data[key]
                  : item[key];
              if (
                !itemValue ||
                !selectedFilterOptions.includes(
                  itemValue.replace(/[-\s]/g, "").toLowerCase()
                )
              ) {
                return false;
              }
            }
            return true;
          });
          // const filteredEventData = this.mixedEvents.filter((item) => {
          //   // check if item satisfies all selected filters
          //   for (let key in groupedFilter) {
          //     const selectedFilterOptions = groupedFilter[key];
          //     const itemValue = item[key];
          //     if (
          //       !itemValue ||
          //       !selectedFilterOptions.includes(itemValue.replace(/[-\s]/g, ""))
          //     ) {
          //       return false;
          //     }
          //   }
          //   return true;
          // });
          this.libraryItems = filteredFeedData;
          this.events = [];
        } else {
          this.events = this.mixedEvents;
          this.libraryItems = this.mixedLibraryItems.filter(
            (obj) =>
              !this.events.some(
                (removeItem) => removeItem.itemId === obj.itemId
              )
          );
        }
      },
    },
    appView(newVal) {
      this.searchingLibrary = false;
      this.getLibraryItems();
      if (newVal) {
        // this.selectedFilter = [];
        this.$store.commit("library/SELECT_FILTERS", []);
        this.$store.commit("library/UPDATE_SEARCH_ITEM_QUERY", "");
        this.$store.commit("library/EMPTY_SELECTED_ITEM");
      }
    },
  },
  created() {
    let routerHash = this.$route.hash;
    if (routerHash.replace("#", "") === "activate-bot") {
      this.$store.commit("modals/OPEN_CHAT_BOT_POPUP", true);
    }
  },
  methods: {
    removeFilter(filter) {
      const index = this.selectedFilter.findIndex(
        (item) => item.value === filter.value && item.key === filter.key
        // item.label === filter.label &&
        // item.checked === filter.checked
      );
      if (index !== -1) {
        this.selectedFilter.splice(index, 1);
      }
      this.$store.commit("library/SELECT_FILTERS", this.selectedFilter);
      // codes to remove the filters, from the chips list on cross click
    },
    getLibraryItems() {
      if (!this.searchingLibrary) {
        if (this.appView === "userView") {
          this.getUserLibraryItems();
        } else {
          this.getOrgLibraryItems();
        }
      }
    },
    getUserEvents() {
      this.$store
        .dispatch("library/fetchUserEvents", this.orgObj)
        .then((res) => {
          if (this.appView === "userView" && !this.searchingLibrary) {
            this.mixedEvents = [];
            this.events = [];
            this.events = res.map((item) => ({
              ...item,
              itemType: item.type,
            }));

            this.mixedEvents = this.events;
          }
        })
        .catch(() => {
          // console.log(err);
        })
        .finally(() => {
          this.isSkeleton = false;
        });
    },
    getUserLibraryItems() {
      this.isSkeleton = true;
      this.$store
        .dispatch("library/getUserLibraryItems", this.orgObj)
        .then((res) => {
          if (this.appView === "userView" && !this.searchingLibrary) {
            this.mixedLibraryItems = [];
            this.libraryItems = [];
            this.mixedLibraryItems = res.data;
            this.libraryItems = res.data;
          }
        })
        .catch(() => {
          !this.apiCalled ? this.getUserLibraryItems() : "";
          this.apiCalled = true;
          // console.log(err);
          return;
        })
        .finally(() => {
          this.getUserEvents();
        });
    },
    getOrgEvents() {
      this.$store
        .dispatch("library/getEvents")
        .then((res) => {
          if (!this.searchingLibrary) {
            this.mixedEvents = [];
            this.events = [];
            this.events = res.map((item) => ({
              ...item,
              itemType: item.type,
            }));
            this.mixedEvents = this.events;
          }
        })
        .catch(() => {
          // console.log(err);
        })
        .finally(() => {
          this.isSkeleton = false;
        });
    },
    getOrgLibraryItems() {
      this.isSkeleton = true;
      if (this.orgObj.org === "altf") {
        this.$store
          .dispatch("library/getCustomOrgLibraryItems", this.orgObj)
          .then((res) => {
            this.mixedLibraryItems = [];
            this.libraryItems = [];
            this.mixedLibraryItems = res.data;
            this.libraryItems = res.data;
          })
          .catch(() => {
            // console.log(err);
          })
          .finally(() => {
            this.getOrgEvents();
          });
      } else {
        this.$store
          .dispatch("library/getOrgLibraryItems", this.orgObj)
          .then((res) => {
            this.mixedLibraryItems = res.data;
            this.libraryItems = res.data;
          })
          .catch(() => {
            // console.log(err);
          })
          .finally(() => {
            this.getOrgEvents();
          });
      }
    },
    switchPage(page) {
      this.isSkeleton = true;
      this.currentPage = page;
      this.isSkeleton = false;
    },
    searchCourse(query) {
      this.appView === "userView"
        ? this.searchUserCourse(query)
        : this.searchOrgCourse(query);
    },
    searchUserCourse(query) {
      this.isSkeleton = true;
      this.$store
        .dispatch("library/searchUserLibraryItems", {
          userId: this.userData._id,
          query: query,
        })
        .then((res) => {
          this.events = [];
          this.mixedEvents = this.events;
          this.mixedLibraryItems = res.map((item) => ({
            ...item,
            itemType: item.type,
          }));
          this.libraryItems = this.mixedLibraryItems;
        })
        .catch(() => {
          // console.log(err);
        })
        .finally(() => {
          this.isSkeleton = false;
        });
    },
    searchOrgCourse(query) {
      this.isSkeleton = true;
      this.$store
        .dispatch("library/searchOrgLibraryItems", {
          // gtbrddOrganizationId: this.orgObj.gborgid,
          // gtbrddUserId: this.userData._id,
          query: query,
        })
        .then((res) => {
          this.events = [];
          this.mixedEvents = this.events;

          this.mixedLibraryItems = res.map((item) => ({
            ...item,
            itemType: item.type,
          }));
          this.libraryItems = this.mixedLibraryItems;
        })
        .catch(() => {
          // console.log(err);
        })
        .finally(() => {
          this.isSkeleton = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.library__container {
  // max-width: 1240px;
  padding: 1rem;
  place-self: center;

  .--event_gap {
    margin-top: 4rem;
    gap: 2rem 2rem;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-content: start;
  }

  .--gap {
    gap: 2rem 2rem;
    justify-content: center;
    place-content: center;
    align-items: stretch;
    flex-flow: row wrap;
    display: grid;
    justify-content: start;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    &__x {
      gap: 0 2rem;
    }
  }

  .btn {
    border-radius: 0.35rem;
    background: #fff;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem 1.25rem;

    &.distribute {
      color: #ff6584;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .empty__library {
    img {
      width: 30%;
    }

    &__text {
      font-size: 2em;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 1368px) {
}

@media only screen and (max-width: 1190px) {
  .library__container {
    padding: 0rem 2rem;

    .--event_gap {
      width: 100%;
      place-content: center;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      justify-content: center;
      gap: 2rem 2rem;
    }

    .--gap {
      justify-content: center;
      gap: 2rem 2rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

@media only screen and (max-width: 800px) {
  .library__container {
    .--event_gap {
      width: 100%;
      place-content: center;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: center;
      gap: 2rem 2rem;
    }

    .--gap {
      justify-content: center;
      gap: 1rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media only screen and (max-width: 500px) {
  .library__container {
    .--event_gap {
      width: 100%;
      place-content: center;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      justify-content: center;
      gap: 2rem 2rem;
    }

    .--gap {
      justify-content: center;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
</style>
