<template>
  <div
    class="py-2 md-pd d-flex align-items-center w-100 h-100 justify-content-center custom-course__background"
    @click="
      $store.commit('modals/OPEN_ADD_CUSTOM_COURSE_POPUP', false);
      $store.commit('library/SELECT_ITEM_INSTRUCTIONS', []);
      blockBodyScroll(false);
    "
  >
    <section @click.stop class="w-60 custom-course position-relative">
      <div class="d-flex align-items-start justify-content-between">
        <div>
          <h2 class="font-weight-bolder heading__black">Add Custom Task</h2>
          <h4 class="font-weight-bolder heading__gray">
            <!-- Distribute assignments to your trainee and colleagues -->
          </h4>
        </div>
        <div
          class="cross-icon d-flex align-items-center justify-content-center cursor-pointer"
          @click="
            $store.commit('modals/OPEN_ADD_CUSTOM_COURSE_POPUP', false);
            blockBodyScroll(false);
          "
        >
        <img src="@/assets/images/icons/cross-icon.svg" alt="">
        </div>
      </div>

      <main class="custom-course__container">
        <section class="left">
          <div class="mt-25">
            <div class="heading">Title</div>
            <b-form-input
              class="textEditor"
              type="text"
              size="lg"
              placeholder="Enter your title here"
              v-model="courseTitle"
            />
            <div class="heading">Instructions</div>
            <editor ref="editor" />

            <div class="heading">Thumbnail</div>
            <b-form-file
              ref="file-input"
              accept="image/*"
              @change="uploadThumbnail"
              class="mt-25 file-input cursor-pointer"
              placeholder="No file chosen"
            />
          </div>

          <div
            class="distribute__btn text-center py-75 mt-2"
            :class="{
              'distribute__btn--opacity':
                isAddBtnDeActive() || uploadingThumbnail,
            }"
            @click="addCustomCourse"
          >
            Add
          </div>
        </section>
      </main>
    </section>
  </div>
</template>

<script>
import { BFormInput, BFormFile, VBTooltip } from "bootstrap-vue";
import constants from "@/constants";
import designMixin from "@/mixins/designMixin.js";
import orgMixin from "@/mixins/orgMixin.js";
import notificationMixin from "@/mixins/notificationMixin.js";
import Editor from "@/components/common/Editor.vue";

export default {
  name: "AddCustomCourse",
  components: {
    BFormInput,
    BFormFile,
    Editor,
  },
  data() {
    return {
      constants,
      editorData: "",
      courseTitle: "",
      uploadingThumbnail: false,
      thumbnailUrl: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [designMixin, orgMixin, notificationMixin],
  computed: {
    userData() {
      return this.$store.state.user.userData;
    },
    appView() {
      return this.$store.state.user.appView;
    },
    itemInstructions() {
      return this.$store.state.library.itemInstructions;
    },
    activeOrgData() {
      return this.$store.state.user.activeOrgData;
    },
    avatarNameGenerator() {
      return this.$store.state.user.avatarNameGenerator;
    },
  },
  watch: {
    appView() {
      this.getDropdownOptions();
    },
    courseTitle() {
      this.isAddBtnDeActive();
    },
    itemInstructions: {
      deep: true,
      immediate: true,
      handler() {
        this.isAddBtnDeActive();
      },
    },
  },
  mounted() {
    this.$store.commit("library/SELECT_ITEM_INSTRUCTIONS", []);
  },
  methods: {
    getEditorData(data) {
      this.editorData = data;
      this.isAddBtnDeActive();
    },
    isAddBtnDeActive() {
      if (this.itemInstructions?.length === 0 || !this.courseTitle) {
        return true;
      }
    },
    uploadThumbnail(event) {
      this.uploadingThumbnail = true;
      const thumbnailImg = event.target.files[0];
      let fileSizeInMB = thumbnailImg.size / (1024 * 1024);
      if (fileSizeInMB <= 1) {
        const formData = new FormData();
        formData.append("thumbnail", thumbnailImg);
        this.$store
          .dispatch("library/uploadPicture", formData)
          .then((res) => {
            this.thumbnailUrl = res?.data?.file?.url;
          })
          .catch(() => {
            let notificationDetail = {
              message: "Please try again !",
              variant: "danger",
            };
            this.showToast(notificationDetail);
          })
          .finally(() => {
            this.uploadingThumbnail = false;
          });
      } else {
        let notificationDetail = {
          message: "File size exceeds 1 MB. Please choose a smaller file.",
          variant: "danger",
        };
        this.showToast(notificationDetail);
      }
    },
    getUserImg() {
      if (this.userData && this.userData.profilePictureUrl) {
        return `${constants.s3Bucket}/editor/profile-pictures/${this.userData.profilePictureUrl}`;
      } else {
        let firstName =
          this.userData && this.userData.firstName
            ? this.userData.firstName
            : "boringAvatar";
        let lastName =
          this.userData && this.userData.lastName
            ? this.userData.lastName
            : "boringAvatar";
        return `https://source.boringavatars.com/beam/100/${this.avatarNameGenerator(
          firstName,
          lastName
        )}`;
      }
    },
    addCustomCourse() {
      let payload = {
        title: this.courseTitle,
        description: this.itemInstructions,
        thumbnail: this.thumbnailUrl,
        imageUrl: this.thumbnailUrl,
        isCustomCourse: true,
      };
      if (this.appView === "userView") {
        payload = {
          ...payload,
          ...{
            source: `${this.userData.firstName} ${
              this.userData.lastName ? this.userData.lastName : ""
            }`,
            sourceLogoUrl: this.getUserImg(),
          },
        };
      } else {
        payload = {
          ...payload,
          ...{
            source: this.activeOrgData.name,
            sourceLogoUrl: `${constants.s3Bucket}/organization/logo/${this.activeOrgData.logo}`,
          },
        };
      }

      this.$store.commit("library/UPDATE_DISTRIBUTED_ITEM_LIST", payload);
      this.$store.commit("modals/OPEN_ADD_CUSTOM_COURSE_POPUP", false);
      this.$store.commit("modals/OPEN_DISTRIBUTE_COURSE_POPUP", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select-lg:focus,
.form-control:focus,
.input-group-lg::v-deep input:focus {
  border-color: var(--org-theme-color) !important;
}

.custom-course {
  max-width: 90%;
  max-height: 90vh;
  // min-height: 90%;
  height: max-content;
  border-radius: 10px;
  background: #ffffff;
  padding: 2.5rem;

  &__background {
    background: #0000006a;
    backdrop-filter: blur(5px);
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  .heading {
    &__black {
      font-size: 2.25rem;
      font-weight: 700 !important;
    }

    &__gray {
      color: #787878;
      font-size: 1.25rem;
      font-weight: 400 !important;
    }
  }

  .cross-icon {
    background: #e6e6e6;
    color: #98a2b3;
    font-size: 1.25em;
    font-weight: 900;
    padding: 0.5em;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    transition: all 0.23s ease 0.1s;
    font-family: "Montserrat", Helvetica, Arial, serif;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-top: 1rem;

    .left {
      min-width: 30rem;
      width: 100%;
      max-width: 35rem;

      &.isRight {
        width: 35%;
      }

      .heading {
        font-size: 1.5rem;
        font-weight: 600 !important;
        color: #000;
        margin-top: 1rem;

        &--2 {
          color: #98a2b3;
          font-weight: 400;
        }
      }

      .distribute__btn {
        background: #ff6584;
        cursor: pointer;
        box-shadow: 0px 0px 14px 0px rgba(255, 101, 132, 0.1);
        color: #fff;
        border-radius: 0.35rem;
        font-size: 1.25rem;
        font-weight: 600;

        &--opacity {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .right {
      min-width: 30rem;
      max-width: 45rem;
      max-height: calc(0.75 * 90vh);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .custom-course {
    .heading {
      &__black {
        font-size: 2rem;
      }
    }

    &__container {
      margin-top: 0rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .custom-course {
    padding: 1.5rem;
    width: 50%;
    overflow-y: scroll;

    &__container {
      flex-direction: column;

      .left {
        min-width: 100%;
        max-width: 100%;
      }

      .right {
        min-width: 100%;
        max-width: 100%;
        max-height: max-content;
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .custom-course {
    width: calc(80% - 20px);
    .heading {
      &__black {
        font-size: 1.5rem;
      }
    }

    .cross-icon {
      font-size: 1.25em;
      height: 1.75em;
      width: 1.75em;
    }

    &__container {
      .left {
        .heading {
          font-size: 1.25rem;
        }
      }
    }
  }
}

input.textEditor {
  // background-color:red;
  width: 100%;
  outline: none;
  // border: 1px solid blue;
  border-radius: 4px;
  padding-left: 1rem;
  margin: 0.5rem 0px;
}
</style>

<style lang="css">
.file-input {
  margin-top: 1rem !important;
  border: none !important;
  height: 4rem !important;
}

.custom-file-input {
  border: none !important;
}

.custom-file-label {
  color: #000712;
  width: 100%;
  padding-left: 11rem;
  border: none !important;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: none !important;
}

/* .custom-file-label::before {
  border: 1px solid #D0D5DD;
  border-radius: 0.5rem;
  background-color: #b31515 !important;
  left: 0rem;
  top: 1rem;
  cursor: pointer;
  z-index: 90;
  width: fit-content;
  content: 'Choose File' !important;
  height: 100%;
  font-size: 1rem;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
} */

.custom-file-label::after {
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  background-color: #ffffff !important;
  left: 0rem;
  cursor: pointer;
  z-index: 40;
  width: fit-content;
  content: "Choose File" !important;
  height: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}

.form-file-text {
  color: #424242;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  white-space: normal;
  font-size: 1rem;
  line-height: 1.4rem;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #7367f0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0);
}
</style>
