<template>
  <div class="checkbox-wrapper">
    <!-- {{ isChecked }} -->
    <label class="checkbox mb-0">
      <input
        class="checkbox__trigger visuallyhidden"
        v-model="isChecked"
        @change="handleChange"
        type="checkbox"
      />
      <span class="checkbox__symbol">
        <svg
          aria-hidden="true"
          class="icon-checkbox"
          width="28px"
          height="28px"
          viewBox="0 0 28 28"
          version="1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 14l8 7L24 7"></path>
        </svg>
      </span>
      <p class="checkbox__textwrapper ml-50">{{ label }}</p>
      <p class="checkbox__textwrapper ml-50 visuallyhidden">{{ isSelected }}</p>
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  data() {
    return {
      // isChecked: false,
    };
  },
  props: {
    label: {
      type: String,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isSelected: {
      immediate: true,
      deep:true,
      handler(newVal) {
        this.isChecked = newVal;
      },
    },
  },
  methods: {
    handleChange() {
      this.$emit("checkbox-checked", this.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/checkbox.scss";
.checkbox-wrapper {
  --c-primary: rgb(255, 101, 132);
  --c-primary-20-percent-opacity: rgba(255, 101, 132, 0.437);
}
.checkbox-wrapper .checkbox__textwrapper {
  color: #948f8f;
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
