<template>
  <div class="parent-horizontal-slider">
    <div class="horizontal-slider" ref="slider" @scroll="handleScroll">
      <div
        class="toLeft scroll-btn"
        @click="scroll(screenWidth >= 1280 ? -500 : -250)"
        :style="removeNavigation(canScrollLeft)"
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1703_1182)">
            <path
              d="M24 4C12.972 4 4 12.972 4 24C4 35.028 12.972 44 24 44C35.028 44 44 35.028 44 24C44 12.972 35.028 4 24 4ZM34 26H24V34L14 24L24 14V22H34V26Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1703_1182"
              x="2"
              y="4"
              width="44"
              height="44"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1703_1182"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1703_1182"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div class="wrap">
        <div
          class="card"
          v-for="(event, index) in events"
          :key="index"
          :class="{
            card__highlight:
              itemId === event.itemId ||
              (selectedItem &&
                selectedItem.some((item) => item.itemId === event.itemId)),
          }"
          @mouseenter="itemId = event.itemId"
          @mouseleave="itemId = ''"
          target="_blank"
        >
          <div
            class="card__checkbox"
            :class="{
              card__checkbox__highlight:
                itemId === event.itemId ||
                (selectedItem &&
                  selectedItem.some((item) => item.itemId === event.itemId)),
            }"
          >
            <CheckboxWhite :value="event" @checkbox-value="getSelectedValue" />
          </div>

          <!-- Your content goes here -->
          <img
            class="left"
            @click="redirectPage(event)"
            :src="event.imageUrl ? event.imageUrl : randomFeedImage(event)"
            @error="randomFeedImage(event)"
          />
          <div class="right" @click="redirectPage(event)">
            <div class="title">
              {{
                event.title.length > 15
                  ? `${event.title.slice(0, 15)}...`
                  : event.title
              }}
            </div>
            <div class="details">
              <div class="rows">
                <img src="@/assets/images/icons/calender2.svg" alt="Calender" />
                <div>
                  {{ event.startDate }}
                </div>
              </div>
              <div class="rows">
                <img src="@/assets/images/icons/location2.svg" alt="Location" />
                <div style="text-transform: capitalize">
                  {{
                    event.location.length > 15
                      ? `${event.location.slice(0, 15)}...`
                      : event.location
                  }}
                </div>
              </div>
              <div class="rows">
                <img
                  height="24"
                  width="24"
                  :src="event.sourceLogoUrl"
                  @error="altSrcImage"
                />
                <div>
                  {{ event.organizer }}
                </div>
              </div>
            </div>
          </div>
          <!-- Add more content as needed -->
        </div>
      </div>
      <div
        class="toRight scroll-btn"
        @click="scroll(screenWidth >= 1280 ? 500 : 250)"
        :style="removeNavigation(canScrollRight)"
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1703_1186)">
            <path
              d="M24 4C12.972 4 4 12.972 4 24C4 35.028 12.972 44 24 44C35.028 44 44 35.028 44 24C44 12.972 35.028 4 24 4ZM24 34V26H14V22H24V14L34 24L24 34Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1703_1186"
              x="2"
              y="4"
              width="44"
              height="44"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1703_1186"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1703_1186"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxWhite from "../common/CheckboxWhite.vue";

export default {
  name: "EventsSlider",
  components: {
    CheckboxWhite,
  },
  data() {
    return {
      canScrollLeft: false,
      canScrollRight: false,
      scrollLeft: 0,
      isDragging: false,
      startPosition: 0,
      currentTranslate: 0,
      itemId: "",
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedItem() {
      return this.$store.state.library.selectedItem;
    },
    activeOrgData() {
      return this.$store.state.user.activeOrgData;
    },
  },
  mounted() {
    this.updateScrollButtons();
    window.addEventListener("resize", this.updateScrollButtons);
    this.scrollArea = this.$refs.slider.scrollWidth;
    this.scrollTwo = this.$refs.slider.clientWidth;
    window.addEventListener("resize", this.handleResize);
    this.scroll(this.screenWidth >= 1280 ? 150 : 220);
  },
  methods: {
    handleResize() {
      // Update screen size when the window is resized
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },

    removeNavigation(x) {
      // Your logic for determining opacity goes here
      // For example, return a higher opacity if the user is logged in
      return x
        ? "opacity: 1; pointer-events: all ;"
        : "opacity: 0; pointer-events: none ;";
    },

    handleScroll() {
      this.scrollLeft = this.$refs.slider.scrollLeft;
      this.updateScrollButtons();
    },
    updateScrollButtons() {
      const slider = this.$refs.slider;
      this.canScrollLeft = this.scrollLeft > 0;
      this.canScrollRight =
        this.scrollLeft < slider.scrollWidth - slider.clientWidth;
    },
    scroll(amount) {
      const slider = this.$refs.slider;
      this.scrollLeft = Math.max(this.scrollLeft + amount, 0);
      // this.scrollLeft +=amount;
      slider.scrollLeft = this.scrollLeft;
      // this.updateScrollButtons()
    },
    getSelectedValue(item) {
      this.$store.commit("library/UPDATE_DISTRIBUTED_ITEM_LIST", item);
    },
    randomFeedImage(item) {
      let i = 15; // i = number of given random images
      let alternateImgUrl = require(`@/assets/images/library/blank-feed/${[
        Math.floor(Math.random() * i),
      ]}.png`);
      item.imageUrl = alternateImgUrl;
      return alternateImgUrl;
    },
    altSrcImage(e) {
      return (e.target.src = `https://source.boringavatars.com/ring`);
    },
    redirectPage(item) {
      const { gbuid, gborgid } = this.activeOrgData;
      let queryParams = {
        itemId: item.itemId,
        type: item.itemType || item.type,
        gbuid: gbuid,
        gborgid: gborgid,
      };
      const routeData = this.$router.resolve({
        name: "Redirect Page",
        query: queryParams,
      });
      return window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.parent-horizontal-slider {
  display: flex;
  width: 100%;
  margin-top: -2rem;
  justify-content: center;
}

.horizontal-slider {
  overflow-x: hidden;
  overflow-y: hidden;
  /* white-space: nowrap;  */
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1240px;
  scroll-behavior: smooth;
}

.wrap {
  padding: 0rem 2rem 0rem 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  height: fit-content;
  position: relative;
}

$colors: #6dbdef #2ba7ec, #9582f5 #6f59fa, #ff878f #f05f73, #8fea47 #0cbd68,
  #d9afd9 #97d9e1, #fbab7e #f7ce68, #8bc6ec #9599e2, #4158d0 #ffcc70,
  #0093e9 #80d0c7, #8ec5fc #e0c3fc, #d9afd9 #97d9e1, #00dbde #fc00ff,
  #fbab7e #f7ce68, #85ffbd #fffb7d, #fbda61 #ff5acd, #ff9a8b #ff6a88,
  #fa8bff #2bd2ff, #21d4fd #b721ff, #3eecac #ee74e1, #08aeea #2af598;
@for $i from 1 through length($colors) {
  .card:nth-child(#{$i}n) {
    $startColor: nth(nth($colors, $i), 1);
    $endColor: nth(nth($colors, $i), 2);
    background: linear-gradient(
      180deg,
      $startColor 0%,
      $endColor 100%
    ) !important;
  }
}

.card {
  width: 400px;
  position: relative;
  height: 200px;
  display: flex;
  padding: 20px;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  //   border:2px solid white;
  //   background-color: #ff6584;
  margin: 0px;
  color: white;
  border: 2px solid #f8f8f8;
  box-shadow: none;
  transition: all ease-in 0.1s;
  /* Style for content inside the container */

  &__highlight {
    border: 2px solid #ff6584;
  }

  &:hover {
    border: 2px solid #ff6584;
    // box-shadow: 0px 0px 14px 5px rgb(255 101 132 / 40%);
  }

  &__checkbox {
    position: absolute;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    margin-top: -0.1rem;
    margin-bottom: -3rem;
    z-index: 1;
    padding: 0.8rem;
    background-color: #ff6584;
    border-radius: 12px 0 0.5rem 0;
    opacity: 1;
    transform: scale(0);
    display: block;
    transition: all ease-in 0.2s;
    top: 0px;
    left: -2px;
    transform-origin: 0% 0%;

    &__highlight {
      background-color: #ff6584;
      position: absolute;
      transform: scale(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }
}

.left {
  flex: 1;
  height: 100%;
  width: 10%;
  border-radius: 12px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  //   padding: 30px 30px 30px 0px;
}

.right {
  flex: 1;
}

.title {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 22px;
}

.details {
  /* padding:10px 0px; */
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: #ffffff;
  font-weight: 600;
}

.rows {
  display: flex;
  /* background-color: red; */
  align-items: center;
  gap: 16px;
}

.scroll-btn {
  position: sticky;
  height: 100%;
  /* width: 200px; */
  top: 0px;
  /* left: 0px; */
  z-index: 2;
  display: flex;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

.scroll-btn svg {
  align-self: center;
}

.toLeft {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #f8f8f8 100%);
  left: 0px;
  padding: 0px 0px 0px 40px;
  margin-right: -8rem;
}

.toRight {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f8f8f8 100%);
  padding: 0px 40px 0px 0px;
  right: 0px;
}

@media screen and (max-width: 1280px) {
  .parent-horizontal-slider {
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 95vw;
    overflow: hidden;

    .horizontal-slider {
      .wrap {
        padding: 0rem 2rem 3rem 2rem;

        .card {
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .parent-horizontal-slider {
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 95vw;
    overflow: hidden;

    .horizontal-slider {
      .wrap {
        .card {
          flex-direction: column;
          height: fit-content;
          width: 250px;
          padding: 1.5rem;

          .left {
            min-height: 15rem;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .horizontal-slider {
    padding-bottom: 3rem;
  }

  .toLeft {
    padding: 0.5rem;
    margin-right: -5rem;
    background: none;
    height: fit-content;
    justify-content: center;
    align-items: center;
    top: 100% !important;
    background: #ff6584;
    border-radius: 1rem;
    left: 30%;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .toRight {
    padding: 0.5rem;
    margin-right: -5rem;
    background: none;
    height: fit-content;
    justify-content: center;
    align-items: center;
    top: 100% !important;
    background: #ff6584;
    border-radius: 1rem;
    right: 30%;

    svg {
      width: 35px;
      height: 35px;
    }
  }
}

@media screen and (max-width: 600px) {
  .card {
    // display: none;
  }
}
</style>
