const messagingConfig = {
  messages: {
    error: {
      unknown_error: "Oops! Something went wrong. Please try again later.",
      non_authenticated_error: "You need to be logged in to continue.",
    },
    not_loggedIn: {
      text: "Login to interact with the app.",
    },
    success: {
      courseDistribution: (courseLength) => {
        return `${
          courseLength === 1 ? "Course" : "Courses"
        }  distributed successfully !`;
      },
    },
  },
};



export { messagingConfig };
