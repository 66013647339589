<template>
  <div class="w-100 d-flex justify-content-center w-100" style="background-color: #f9fafb;">
    <footer class="w-100" style="max-width: 1240px;">
      <!-- <b-row class="d-flex justify-content-between padding--x">
      <b-col sm="12" lg="4" xl="3"
        ><a href="/">
          <img
            class="company-logo"
            :src="
              require(`@/assets/images/logo/getboarded-${currentTheme}.svg`)
            "
            alt="Getboarded"
          />
        </a>
        <p class="company-description mt-75">
          Our mission is to empower the world is professionals to take control
          of their personal brand and become more successful.
        </p></b-col
      >

      <b-col class="d-flex flex-wrap px-0 px-lg-1" cols="12" lg="8">
        <b-col cols="12" md="3" lg="3" xl="3"
          ><div
            class="mb-75 list d-flex align-items-center justify-content-between"
            v-b-toggle="`${isDeviceSmall ? 'company' : ''}`"
          >
            Company
            <feather-icon class="d-md-none" size="15" icon="ChevronDownIcon" />
          </div>
          <b-collapse class="d-md-flex" id="company">
            <div class="d-flex flex-column">
              <a class="mb-50 list-points link-hover">Home</a>
              <a class="mb-50 list-points link-hover">Press</a>
              <a class="mb-50 list-points link-hover"
                >Careers <span class="ml-50 pill pill-new">New</span></a
              >
              <a class="mb-50 list-points link-hover">Media kit</a>
              <a class="mb-50 list-points link-hover">Support</a>
            </div>
          </b-collapse>
        </b-col>
        <b-col cols="12" md="3" lg="" xl="3"
          ><div
            class="mb-75 list d-flex align-items-center justify-content-between"
            v-b-toggle="`${isDeviceSmall ? 'partners' : ''}`"
          >
            Partners
            <feather-icon class="d-md-none" size="15" icon="ChevronDownIcon" />
          </div>
          <b-collapse class="d-md-flex" id="partners">
            <div class="d-flex flex-column">
              <a class="mb-50 list-points link-hover">Companies</a>
              <a class="mb-50 list-points link-hover">Universities</a>
              <a class="mb-50 list-points link-hover">Events</a>
              <a class="mb-50 list-points link-hover">Contact</a>
              <a class="mb-50 list-points link-hover">Emotionhack</a>
            </div>
          </b-collapse>
        </b-col>
        <b-col cols="12" md="4" lg="" xl="3"
          ><div
            class="mb-75 list d-flex align-items-center justify-content-between"
            v-b-toggle="`${isDeviceSmall ? 'integrations' : ''}`"
          >
            Integrations
            <feather-icon class="d-md-none" size="15" icon="ChevronDownIcon" />
          </div>
          <b-collapse class="d-md-flex" id="integrations">
            <div class="d-flex flex-column">
              <a class="mb-50 list-points link-hover">Resume Editor</a>
              <a class="mb-50 list-points link-hover">Mentor</a>
              <a class="mb-50 list-points link-hover">HiBob</a>
              <a class="mb-50 list-points link-hover">Career Lab</a>
              <a class="mb-50 list-points link-hover">Resume Parser</a>
            </div>
          </b-collapse>
        </b-col>
        <b-col cols="12" md="2" lg="2" xl="3"
          ><div
            class="mb-75 list d-flex align-items-center justify-content-between"
            v-b-toggle="`${isDeviceSmall ? 'legal' : ''}`"
          >
            Legal
            <feather-icon class="d-md-none" size="15" icon="ChevronDownIcon" />
          </div>
          <b-collapse class="d-md-flex" id="legal">
            <div class="d-flex flex-column">
              <a class="mb-50 list-points link-hover">Terms</a>
              <a class="mb-50 list-points link-hover">Privacy</a>
              <a class="mb-50 list-points link-hover">Cookies</a>
              <a class="mb-50 list-points link-hover">Licenses</a>
              <a class="mb-50 list-points link-hover">Settings</a>
            </div>
          </b-collapse>
        </b-col>
      </b-col>
    </b-row> -->

      <b-row
        class="d-flex justify-content-between footer-secondary flex-column-reverse flex-md-row m-0"
      >
        <b-col md="6" class="mt-50 mt-md-0">
          <a
            class="rights link-hover"
            href="https://getboarded.com/privacy-policy"
            >© {{currentYear}} GetBoarded. All rights reserved.</a
          ></b-col
        >
        <b-col class="d-flex social-icons" md="5" lg="4" xl="3">
          <b-col
            md="2"
            v-for="(socialHandle, index) in socialHandles"
            :key="index"
          >
            <a target="_blank" :href="socialHandle.link"
              ><img class="hover-effect-img" :src="socialHandle.icon" alt=""
            /></a>
          </b-col>
        </b-col>
      </b-row>
    </footer>
    <!-- Animated Avatar -->
    <div
      v-if="animatedGif"
      class="chatbot__animated-avatar-bg position-fixed w-100 h-100"
      @click.stop="completeAnimation"
    >
      <img
        class="chatbot__animated-avatar position-absolute"
        :src="
          require(`@/assets/images/organization/${orgObj.orgSlug}/animation.gif`)
        "
      />
    </div>

    <!-- Chatbot  -->
    <div
      v-if="userData && !animatedGif"
      class="chatbot d-flex align-items-center justify-content-end cursor-pointer"
    >
      <img
        v-if="!chatBotPopup"
        @click="openChatBotPopup"
        class="chatbot-ale w-100"
        :src="
          require(`@/assets/images/organization/${orgObj.orgSlug}/sleeping-bot-avatar.png`)
        "
        alt="Ale"
      />

      <img
        v-else
        @click="openChatBotPopup"
        class="chatbot-ale w-100"
        :src="
          require(`@/assets/images/organization/${orgObj.orgSlug}/awake-bot-avatar.png`)
        "
        alt="Ale"
      />
    </div>

    <!-- chatbot modal  -->
    <chatbot-modal
      @click="openChatBotPopup"
      v-if="chatBotPopup && userData"
      class="position-absolute"
    />
  </div>
</template>

<script>
import { BRow, BCol, VBToggle } from "bootstrap-vue";
import socialHandles from "@/assets/data/socialHandles.js";
import designMixin from "@/mixins/designMixin.js";
import orgMixin from "@/mixins/orgMixin.js";
import ChatbotModal from "@/modals/ChatbotModal.vue";
export default {
  name: "Footer",
  mixins: [designMixin, orgMixin],
  components: {
    BCol,
    BRow,
    ChatbotModal,
  },
  data() {
    return {
      socialHandles,
      animatedGif: false,
      currentYear: 2024,
    };
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    chatBotPopup() {
      return this.$store.state.modals.chatBotPopup;
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mounted() {
    this.animationCompletion();
    let date = new Date();
    this.currentYear = date.getFullYear();
  },
  methods: {
    openChatBotPopup() {
      if (!this.chatBotPopup) {
        return this.$store.commit("modals/OPEN_CHAT_BOT_POPUP", true);
      } else {
        this.$store.commit("modals/OPEN_CHAT_BOT_POPUP", false);
      }
    },
    animationCompletion() {
      if (this.orgObj.orgSlug && this.orgObj.isChatBotAnimation) {
        let isAnimationPlayed = sessionStorage.getItem("isAnimationPlayed");
        if (!isAnimationPlayed) {
          this.animatedGif = true;
          setTimeout(() => {
            this.completeAnimation();
          }, this.orgObj.gifTime);
        }
      }
    },
    completeAnimation() {
      this.animatedGif = false;
      sessionStorage.setItem("isAnimationPlayed", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.company {
  &-description {
    color: $gray-500;
  }
  &-logo {
    width: 13.5em;
  }
}

.pill {
  padding: 0.3em 0.6em;
  border-radius: 1em;

  &-new {
    background: $success-50;
    color: $success-700;
  }
  &-listening {
    background: $warning-50;
    color: $warning-700;
  }
}

.list {
  cursor: auto;
  font-weight: 600;
  color: $gray-400;
  &-points {
    color: $gray-500;
    font-weight: 600;
  }
}

.link-hover:hover {
  color: #e82b51;
}

.hover-effect-img:hover {
  filter: invert(39%) sepia(130%) saturate(4900%) hue-rotate(326deg)
    brightness(99%) contrast(150%);
}

.rights {
  color: $gray-400;
}
.footer-secondary {
  background: $light-secondary;
  padding-top: 3.5em;
  padding-bottom: 3.5em;
}

.chatbot {
  right: 1em;
  bottom: 2.5em;
  z-index: 1000;
  position: fixed;
  width: 3.5%;
  &-ale {
    box-shadow: 0px 30px 40px 20px rgb(99 132 227 / 10%);
    background: #fffffffa;
    border: 2px solid #ffffff;
    border-radius: 50%;
    padding: 0.5em;
  }
  &__animated-avatar {
    &-bg {
      background: #0000006a;
      backdrop-filter: blur(4px);
      z-index: 7;
      width: 100%;
      top: 0;
      left: 0;
    }
    width: 40vh;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

.dark-layout {
  .footer-secondary {
    background: $dark-secondary;
  }
}

@media only screen and (max-width: 1400px) {
  .footer-secondary {
    padding-left: 4em;
  }
}

@media only screen and (max-width: 991px) {
  .chatbot {
    bottom: 3em;
    width: 5%;
  }
}

@media only screen and (max-width: 768px) {
  .footer-secondary {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 3em;
  }

  .company {
    &-logo {
      width: 11em;
    }
  }

  .chatbot {
    width: 8%;
  }
}

@media only screen and (max-width: 600px) {
  .list {
    margin-top: 0.25em;
  }
  .footer-secondary {
    padding: 1em;
  }
  .social-icons {
    margin-bottom: 1em;
  }
  .rights {
    padding-left: 1rem;
  }



  .company {
    &-logo {
      width: 9.5em;
    }
  }
}
</style>
