let languageTypes = [
  { label: "English", value: "English", key: "language" },
  { label: "Português", value: "Portuguese", key: "language" },
  { label: "Française", value: "French", key: "language" },
  // { label: 'Deutsche', value: 'Dutch', key: 'language' },
  // { label: 'Nederlands', value: 'NL', key: 'language' },
  { label: "Española", value: "Spanish", key: "language" },
  { label: "Italiana", value: "Italian", key: "language" },
  // { label: 'Arabic', value: 'Arabic', key: 'language' },
];

let learningFormatTypes = [
  { label: "Watching", value: "WATCH", key: "format" },
  { label: "Reading", value: "READ", key: "format" },
  { label: "Listening", value: "LISTEN", key: "format" },
  { label: "Participation", value: "PARTICIPATE", key: "format" },
  // { label: 'Interacting', value: 'INTERACT', key: 'format' },
];

let learningSkillTypes = [
  { label: "Functional Skills", value: "tech", key: "itemType" },
  { label: "Soft Skills", value: "soft", key: "itemType" },
  { label: "Events", value: "event", key: "itemType" },
  // { label: 'Interacting', value: 'INTERACT', key: 'itemType' },
];

export { languageTypes, learningFormatTypes, learningSkillTypes };

