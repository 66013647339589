<template>
  <div
    @click="
      $store.commit('modals/OPEN_SEARCH_POPUP', false);
      blockBodyScroll(false);
    "
    class="py-2 md-pd d-flex align-items-center w-100 h-100 justify-content-center search__background"
  >
    <section @click.stop>
      <div class="search__input-container">
        <img src="@/assets/images/icons/search.svg" alt="search" />
        <input
          placeholder="Search Library..."
          v-model="searchQuery"
          ref="searchQueryInput"
          v-on:keyup.enter="searchLibrary"
        />
      </div>
    </section>
  </div>
</template>
<script>
import designMixin from "@/mixins/designMixin.js";

export default {
  name: "SearchModal",
  data() {
    return {
      searchQuery: "",
    };
  },
  mixins: [designMixin],

  props: {
    orgSlug: {
      type: String,
    },
  },
  computed: {},
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.searchQueryInput.focus();
    },
    searchLibrary() {
      this.$store.commit("library/UPDATE_SEARCH_ITEM_QUERY", this.searchQuery);
      this.$store.commit("modals/OPEN_SEARCH_POPUP", false);
      this.blockBodyScroll(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  width: 97.5%;
  height: 100%;
  border-radius: 10px;

  &__background {
    background: #0000006a;
    backdrop-filter: blur(5px);
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
  }

  &__heading {
    &--1 {
      font-size: 1.75em;
    }
  }
  &__input-container {
    place-self: center;
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 0.6rem;
    padding: 0.25rem 1rem;
    margin-top: -15rem;

    input {
      font-size: 1.125rem;
      font-weight: 400;
      color: black;
      padding: 1rem;
      width: 30rem;
      border: none;
      outline: none;

      ::placeholder {
        color: #a9a9a9;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .md-pd {
    padding: 1.5em !important;
  }
}

@media only screen and (max-width: 660px) {
  .search {
    &__heading {
      &--1 {
        font-size: 1.5em;
      }
    }
    &__input-container {
      input {
        width: 22rem;
      }
    }
  }
}
</style>
