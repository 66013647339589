<template>
  <div class="w-100 h-100 filter-modal__background">
    <section class="w-60 filter-modal position-relative">
      <div
        class="px-1 pt-1 pb-50 px-md-2 pt-md-2 d-flex align-items-start justify-content-between"
      >
        <div>
          <h2 class="font-weight-bolder heading__black">Filter</h2>
        </div>
        <div
          class="cross-icon d-flex align-items-center justify-content-center cursor-pointer"
          @click="
            $store.commit('modals/OPEN_FILTER_POPUP', false);
            blockBodyScroll(false);
          "
        >
        <img src="@/assets/images/icons/cross-icon.svg" alt="">
        </div>
      </div>
      <main>
        <Filters :itemSources="itemSources" />
      </main>
    </section>
  </div>
</template>

<script>
import Filters from "@/components/filters/Filters.vue";
import designMixin from "@/mixins/designMixin.js";
export default {
  components: {
    Filters,
  },
  mixins: [designMixin],
  props: {
    itemSources: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 700px) {
  .filter-modal {
    max-width: 100%;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 65%;
    &__background {
      padding: 0px;
      margin: 0;
      align-items: end;
      display: flex;
      align-items: end;
    }
  }
}

@media screen and (min-width: 700px) {
  .filter-modal {
    border-radius: 10px;
    max-height: 60%;
    min-height: 60%;
    min-width: 60%;
    &__background {
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.filter-modal {
  // max-width: 90%;
  // max-height: 50%;
  // border-radius: 10px;
  // min-height: 50%;
  // min-width: 50%;
  background: white;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  // padding:2.5rem;
  main {
    display: flex;
    flex: 1;
  }
  &__background {
    background: #0000006a;
    backdrop-filter: blur(5px);
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    // display: flex;
    // align-items: end;
    // justify-content: center;
  }
  .heading {
    &__black {
      font-size: 2.25rem;
      font-weight: 700 !important;
    }
    &__gray {
      color: #787878;
      font-size: 1.25rem;
      font-weight: 400 !important;
    }
  }
  .cross-icon {
    background: #e6e6e6;
    color: #98a2b3;
    font-size: 1.25em;
    font-weight: 900;
    padding: 0.5em;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    transition: all 0.23s ease 0.1s;
    font-family: "Montserrat", Helvetica, Arial, serif;
  }
}
</style>
