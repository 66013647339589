<template>
  <div
    @click="handleChatBotPopup"
    class="py-3 md-pd d-flex align-items-center w-100 h-100 justify-content-end chatbot__modal__container"
  >
    <section
      @click.stop
      class="w-60 p-1 chatbot__modal__container__chat position-relative"
      :class="{ 'choose-skill__chat': !openiframe }"
    >
      <div
        @click="handleChatBotPopup"
        class="d-flex position-absolute align-items-center justify-content-center cross-icon cursor-pointer cross-icon"
      >
      <img src="@/assets/images/icons/cross-icon.svg" alt="">
      </div>

      <iframe
        v-if="openiframe && !typeFormLink"
        class="w-100 h-100"
        :src="`${constants.chatBotUrl}?name=${encodeURIComponent(
          `${userData.firstName ? userData.firstName : ''} ${
            userData.lastName ? userData.lastName : ''
          }`
        )}&email=${userData.email}&orgSlug=${orgObj.orgSlug}&userId=${
          userData._id
        }&organizationId=${orgObj.gborgid}&token=${
          authDetails.accessToken
        }&tzn=${encodeURIComponent(timeZone)}`"
        frameBorder="0"
      ></iframe>

      <iframe
        v-if="openiframe && typeFormLink"
        class="w-100 h-100"
        :src="`https://gtbrdd.typeform.com/c/${formId}#first_name=${encodeURIComponent(
          `${orgObj.first_name}`
        )}&email=${orgObj.email}&org=${orgObj.org}&gbuid=${
          orgObj.gbuid
        }&gborgid=${orgObj.gborgid}&tzn=${encodeURIComponent(timeZone)}`"
        frameBorder="0"
      ></iframe>

      <div
        v-else-if="!openiframe"
        class="choose-skill d-flex flex-column justify-content-around"
      >
        <div class="choose-skill__avatar">
          <img
            :src="
              require(`@/assets/images/organization/${orgObj.org}/bot-avatar.png`)
            "
            alt=""
            @error="
              $event.target.src =
                '@/assets/images/organization/default/bot-avatar.png'
            "
          />
        </div>

        <b-dropdown
          block
          id="dropdown-left"
          class="mt-1 w-100"
          text="What type of learning do you want to share"
        >
          <b-dropdown-item @click="addTechSkill"
            >Functional (Tech Skill)</b-dropdown-item
          >
          <b-dropdown-item @click="addSoftSkill">
            Behavioral (Soft Skill)</b-dropdown-item
          >
          <b-dropdown-item @click="addEvent">Events</b-dropdown-item>
        </b-dropdown>
      </div>
    </section>
  </div>
</template>
<script>
import orgMixin from "@/mixins/orgMixin.js";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import constants from "@/constants";

export default {
  name: "ChatBotModal",
  components: { BDropdown, BDropdownItem },
  mixins: [orgMixin],
  data() {
    return {
      constants,
      formId: "",
      timeZone: "",
      typeFormLink: false,
    };
  },
  computed: {
    currentTheme() {
      return this.$store.state.user.themeType;
    },
    openiframe() {
      return this.$store.state.user.openiframe;
    },
    authDetails() {
      return this.$store.state.auth;
    },
    userData() {
      return this.$store.state.user.userData;
    },
  },

  mounted() {
    if (
      this.orgObj &&
      this.orgObj.first_name &&
      this.orgObj.email &&
      this.orgObj.gborgid &&
      this.orgObj.gbuid &&
      this.orgObj.form_id
    ) {
      this.formId = this.orgObj.form_id;
    }
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  methods: {
    handleChatBotPopup() {
      this.$store.commit("modals/OPEN_CHAT_BOT_POPUP", false);
      if (!this.openiframe) {
        this.$store.commit("user/OPEN_IFRAME", true);
      }
    },
    addTechSkill() {
      this.typeFormLink = true;
      this.formId = this.orgObj.techSkillFormId;
      this.$store.commit("user/OPEN_IFRAME", true);
    },
    addSoftSkill() {
      this.typeFormLink = true;
      this.formId = this.orgObj.softSkillFormId;
      this.$store.commit("user/OPEN_IFRAME", true);
    },
    addEvent() {
      this.typeFormLink = true;
      this.formId = this.orgObj.eventFormId;
      this.$store.commit("user/OPEN_IFRAME", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cross-icon {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  top: 0;
  font-size: 1.5em;
  padding: 0.5em;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  top: -0.5em;
  right: -0.5em;
  transition: all 0.23s ease 0.1s;
  transform: translate(8px, -2px);
  font-family: "Montserrat", Helvetica, Arial, serif;
  background: #fffffffa;
  // For hover effect of close btn
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    outline: none;
    transform: translate(5px, 3px);
    box-shadow: none;
  }
}

.b-dropdown ::v-deep .dropdown-toggle {
  white-space: normal;
}

.chatbot__modal {
  &__container {
    background: #0000006a;
    backdrop-filter: blur(5px);
    z-index: 5;
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    // z-index: 1050;
    &__chat {
      width: 25%;
      height: 75%;
      right: 2.5em;
      top: 4em;
      border-radius: 10px;
      background: #fff;
      .text {
        color: #1c1c1e;
      }
    }

    .choose-skill__chat {
      width: 23%;
      height: auto;
      top: 30%;
    }

    .choose-skill {
      &__avatar {
        img {
          width: 30%;
        }
      }
      &__btns {
        .btn {
          padding: 1em;
          background-color: #82868b;
          color: #ffffff;
          width: 55%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .chatbot__modal {
    &__container {
      .choose-skill {
        &__btns {
          .btn {
            width: 70%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .chatbot__modal {
    &__container {
      &__chat {
        width: 30%;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .md-pd {
    padding: 1.5em !important;
  }
  .chatbot__modal {
    &__container {
      &__chat {
        height: 50%;
        right: 0.5em;
        top: 15em;
        width: 50%;
      }

      .choose-skill__chat {
        width: 30%;
        top: 20%;
      }
      .choose-skill {
        &__avatar {
          img {
            width: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .md-pd {
    padding: 1.5em !important;
  }
  .chatbot__modal {
    &__container {
      &__chat {
        right: 0.5em;
        width: 50%;
        height: 75%;
        top: 1em;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .chatbot__modal {
    &__container {
      &__chat {
        width: 95%;
        height: 75%;
        top: 2em;
      }

      .choose-skill__chat {
        width: 95%;
        top: 25%;
      }
      .choose-skill {
        &__avatar {
          img {
            width: 40%;
          }
        }
      }
    }
  }
}
</style>
