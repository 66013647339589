<template>
  <div class="course cursor-pointer h-100">
    <div class="course__img__container">
      <b-skeleton-img />
    </div>
    <b-col class="p-1 pb-3">
      <div class="d-flex align-items-center mb-1">
        <b-skeleton type="avatar" />
        <b-skeleton animation="wave" width="25%" class="m-0 ml-50" />
      </div>
      <b-skeleton animation="wave" width="30%" />
      <p class="mb-1">
        <b-skeleton animation="wave" />
        <b-skeleton animation="wave" />
        <b-skeleton animation="wave" />
      </p>

      <b-skeleton type="input" width="20%" class="pt-50"></b-skeleton>
    </b-col>
  </div>
</template>
<script>
import { BCol, BSkeleton, BSkeletonImg } from "bootstrap-vue";
export default {
  name: "CourseSkeleton",
  data() {
    return {};
  },
  components: {
    BCol,
    BSkeleton,
    BSkeletonImg,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
.course {
  background: $light-primary;
  box-shadow: 0px 30px 40px 20px rgba(99, 132, 227, 0.1);
  border-radius: 12px;
  width: 20rem;

  &__img__container {
    height: 10em;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
  }
  .b-skeleton-input {
    height: 1.5em;
    border-radius: 2em;
  }
}

@media only screen and (max-width: 600px) {
  .course {
    &__img__container {
      height: 10em;
    }
  }
}
</style>
