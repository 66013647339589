<template>
  <div
    class="item__card cursor-pointer"
    :class="{
      item__card__highlight:
        itemId === libraryItem.itemId ||
        (selectedItem &&
          selectedItem.some((item) => item.itemId === libraryItem.itemId)),
    }"
    @mouseenter="itemId = libraryItem.itemId"
    @mouseleave="itemId = ''"
  >
    <div
      class="item__card__checkbox"
      :class="{
        item__card__checkbox__highlight:
          itemId === libraryItem.itemId ||
          (selectedItem &&
            selectedItem.some((item) => item.itemId === libraryItem.itemId)),
      }"
    >
      <CheckboxWhite :value="libraryItem" @checkbox-value="getSelectedValue" />
    </div>

    <div class="item__card__img" @click="redirectPage(libraryItem)">
      <img
        :src="
          libraryItem.imageUrl
            ? libraryItem.imageUrl
            : randomFeedImage(libraryItem)
        "
        class=""
        @error="randomFeedImage(libraryItem)"
      />
    </div>

    <div
      class="px-2 pt-2 pb-1 item__card__info"
      @click="redirectPage(libraryItem)"
    >
      <div class="item__card__info__head mb-1">
        <img
          :src="libraryItem.sourceLogoUrl"
          :alt="libraryItem.source ? libraryItem.source : 'GetBoarded'"
          @error="altSrcImage"
          class="mr-1"
        />

        <h5>{{ libraryItem.source ? libraryItem.source : "GetBoarded" }}</h5>
      </div>

      <div class="item__card__info__desc">
        <h6 class="pop">
          {{ libraryItem.title }}
        </h6>
        <p class="inter">
          {{ libraryItem.description }}
        </p>
      </div>

      <div class="item__card__info__tags">
        <div v-if="libraryItem.behaviors && libraryItem.behaviors.length > 0">
          <span
            class="text-capitalize"
            v-for="(behavior, index) in libraryItem.behaviors.slice(0, 1)"
            :key="index"
            >#{{ behavior }}</span
          >
        </div>

        <div
          class="justify-content-end"
          :class="
            libraryItem.behaviors && libraryItem.behaviors.length > 0
              ? ''
              : 'w-100'
          "
        >
          <img
            v-if="libraryItem.format.toLowerCase() === 'read'"
            src="@/assets/images/read-icon.svg"
          />
          <img
            v-if="libraryItem.format.toLowerCase() === 'listen'"
            src="@/assets/images/hear-icon.svg"
          />
          <img
            v-if="libraryItem.format.toLowerCase() === 'watch'"
            src="@/assets/images/video-icon.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orgMixin from "@/mixins/orgMixin.js";
import designMixin from "@/mixins/designMixin.js";
import CheckboxWhite from "../common/CheckboxWhite.vue";
export default {
  name: "EventSlider",
  components: {
    CheckboxWhite,
  },
  mixins: [orgMixin, designMixin],
  data() {
    return {
      checkBox: false,
      itemId: "",
    };
  },
  props: {
    libraryItem: {
      required: true,
      type: Object,
    },
  },
  computed: {
    selectedItem() {
      return this.$store.state.library.selectedItem;
    },
  },
  methods: {
    randomFeedImage(item) {
      let i = 15; // i = number of given random images
      let alternateImgUrl = require(`@/assets/images/library/blank-feed/${[
        Math.floor(Math.random() * i),
      ]}.png`);
      item.imageUrl = alternateImgUrl;
      return alternateImgUrl;
    },
    altSrcImage(e) {
      return (e.target.src = `https://source.boringavatars.com/ring`);
      // return (e.target.src = require(`@/assets/images/library/getboarded-logo.svg`));
    },
    redirectPage(item) {
      const { gbuid, gborgid } = this.orgObj;
      let queryParams = {
        itemId: item.itemId,
        type: item.itemType || item.type,
        gbuid: gbuid,
        gborgid: gborgid,
      };
      const routeData = this.$router.resolve({
        name: "Redirect Page",
        query: queryParams,
      });
      return window.open(routeData.href, "_blank");
    },
    getSelectedValue(item) {
      this.$store.commit("library/UPDATE_DISTRIBUTED_ITEM_LIST", item);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap");

.inter {
  font-family: "Inter", sans-serif;
}

.pop {
  font-family: "Poppins", sans-serif;
}
</style>

<style lang="scss">
.item__card {
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  border: 2px solid #efefef;
  width: fit-content;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  height: 100%;
  align-items: start;
  justify-content: start;

  &__checkbox {
    position: relative;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    margin-top: -0.1rem;
    margin-bottom: -3rem;
    z-index: 5;
    padding: 0.8rem;
    background-color: transparent;
    border-radius: 0 0 0.5rem 0;
    opacity: 0;
    transform: scale(0);
    transform-origin: 0% 0%;
    transition: 0.25s ease;
    &__highlight {
      background-color: #ff6584;
      transform: scale(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    object-fit: cover;
    overflow: hidden;

    img {
      border-radius: 0.375rem;
      object-fit: cover;
      width: 100%;
      height: 17rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: start;
    align-items: start;

    &__head {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: fit-content;

      img {
        width: auto;
        max-width: 5rem;
        height: 1.5rem;
      }
    }

    &__desc {
      margin: 0rem;
      padding: 0rem;

      h6 {
        color: #000;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.375rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }

      p {
        color: #7b818e;
        font-size: 0.875rem;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }

    &__tags {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid #f2f2f2;
      padding-top: 1rem;
      width: 100%;
      gap: 2rem;

      span {
        color: #a3a3a3;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: #f0f0f0;
        padding: 0.5rem 0.8rem;
        border-radius: 1rem;
      }

      div {
        display: flex;
        flex-direction: row;

        img {
          width: 2rem;
          height: 2rem;
          margin-left: -0.7rem;
        }
      }
    }
  }

  &__highlight {
    border: 2px solid #ff6584;
  }
  &:hover {
    border: 2px solid #ff6584;
    box-shadow: 0px 0px 14px 5px rgb(255 101 132 / 40%);
  }
}
</style>
