var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item__card cursor-pointer",class:{
    item__card__highlight:
      _vm.itemId === _vm.libraryItem.itemId ||
      (_vm.selectedItem &&
        _vm.selectedItem.some(function (item) { return item.itemId === _vm.libraryItem.itemId; })),
  },on:{"mouseenter":function($event){_vm.itemId = _vm.libraryItem.itemId},"mouseleave":function($event){_vm.itemId = ''}}},[_c('div',{staticClass:"item__card__checkbox",class:{
      item__card__checkbox__highlight:
        _vm.itemId === _vm.libraryItem.itemId ||
        (_vm.selectedItem &&
          _vm.selectedItem.some(function (item) { return item.itemId === _vm.libraryItem.itemId; })),
    }},[_c('CheckboxWhite',{attrs:{"value":_vm.libraryItem},on:{"checkbox-value":_vm.getSelectedValue}})],1),_c('div',{staticClass:"item__card__img",on:{"click":function($event){return _vm.redirectPage(_vm.libraryItem)}}},[_c('img',{attrs:{"src":_vm.libraryItem.imageUrl
          ? _vm.libraryItem.imageUrl
          : _vm.randomFeedImage(_vm.libraryItem)},on:{"error":function($event){return _vm.randomFeedImage(_vm.libraryItem)}}})]),_c('div',{staticClass:"px-2 pt-2 pb-1 item__card__info",on:{"click":function($event){return _vm.redirectPage(_vm.libraryItem)}}},[_c('div',{staticClass:"item__card__info__head mb-1"},[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.libraryItem.sourceLogoUrl,"alt":_vm.libraryItem.source ? _vm.libraryItem.source : 'GetBoarded'},on:{"error":_vm.altSrcImage}}),_c('h5',[_vm._v(_vm._s(_vm.libraryItem.source ? _vm.libraryItem.source : "GetBoarded"))])]),_c('div',{staticClass:"item__card__info__desc"},[_c('h6',{staticClass:"pop"},[_vm._v(" "+_vm._s(_vm.libraryItem.title)+" ")]),_c('p',{staticClass:"inter"},[_vm._v(" "+_vm._s(_vm.libraryItem.description)+" ")])]),_c('div',{staticClass:"item__card__info__tags"},[(_vm.libraryItem.behaviors && _vm.libraryItem.behaviors.length > 0)?_c('div',_vm._l((_vm.libraryItem.behaviors.slice(0, 1)),function(behavior,index){return _c('span',{key:index,staticClass:"text-capitalize"},[_vm._v("#"+_vm._s(behavior))])}),0):_vm._e(),_c('div',{staticClass:"justify-content-end",class:_vm.libraryItem.behaviors && _vm.libraryItem.behaviors.length > 0
            ? ''
            : 'w-100'},[(_vm.libraryItem.format.toLowerCase() === 'read')?_c('img',{attrs:{"src":require("@/assets/images/read-icon.svg")}}):_vm._e(),(_vm.libraryItem.format.toLowerCase() === 'listen')?_c('img',{attrs:{"src":require("@/assets/images/hear-icon.svg")}}):_vm._e(),(_vm.libraryItem.format.toLowerCase() === 'watch')?_c('img',{attrs:{"src":require("@/assets/images/video-icon.svg")}}):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }