<template>
  <div class="editor__container">
    <div class="editor">
      <div ref="editor" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import notificationMixin from "@/mixins/notificationMixin.js";
// import VuePerfectScrollbar from "vue-perfect-scrollbar";\

class CustomHeading {
  constructor({ data, api }) {
    this.api = api;
    this.title = data.text || "";
    this.placeholder = "Type your heading here";
    this.tag = data.tag || "H2"; // Default to H2 if tag is not provided
    this.styles = {
      width: "100%",
      outline: "none", // Example border style
      padding: "4px", // Example padding
      // Add more styles as needed
    };
  }

  static get toolbox() {
    return {
      title: "Heading",
      icon: "<b>H</b>",
    };
  }

  render() {
    this.container = document.createElement(this.tag);
    this.container.contentEditable = true;
    this.container.classList.add("custom-heading");
    this.container.innerHTML = this.title || this.placeholder;

    // Apply custom styles
    Object.assign(this.container.style, this.styles);

    // Check if there is content to determine whether to show the placeholder
    if (!this.title && !this.container.textContent.trim()) {
      this.container.innerHTML = this.placeholder;
    }

    // Handle focus event to remove the placeholder
    this.container.addEventListener("focus", () => {
      if (this.container.textContent.trim() === this.placeholder) {
        this.container.innerHTML = "";
      }
    });

    // Handle blur event to restore the placeholder if no content is entered
    this.container.addEventListener("blur", () => {
      if (!this.container.textContent.trim()) {
        this.container.innerHTML = this.placeholder;
      }
    });

    return this.container;
  }

  save() {
    return {
      text: this.container.innerHTML,
      tag: this.tag,
    };
  }
  static get enableLineBreaks() {
    return true;
  }
  static get pasteConfig() {
    return {
      tags: ["h1", "h2", "h3", "h4", "h5", "h6"],
    };
  }
}

export default {
  mixins: [notificationMixin],
  data() {
    return {
      editor: null,
      titleEditor: null,
      count: 0,
      links: [
        "https://cdn.jsdelivr.net/npm/@editorjs/header@2.8.1/dist/header.umd.min.js",
        "https://cdn.jsdelivr.net/npm/@editorjs/paragraph@2.11.3/dist/paragraph.umd.min.js",
        "https://cdn.jsdelivr.net/npm/@editorjs/quote@2.6.0/dist/quote.umd.min.js",
        "https://cdn.jsdelivr.net/npm/@editorjs/warning@latest",
        "https://cdn.jsdelivr.net/npm/@editorjs/list@latest",
        "https://cdn.jsdelivr.net/npm/@editorjs/nested-list@latest",
        "https://cdn.jsdelivr.net/npm/@editorjs/link@latest",
        "https://cdn.jsdelivr.net/npm/@editorjs/table@latest",
        // "https://www.jsdelivr.com/package/npm/editorjs-tooltip",
        "https://cdn.jsdelivr.net/npm/@editorjs/attaches@1.3.0/dist/bundle.min.js",
        "https://cdn.jsdelivr.net/npm/@editorjs/image@latest",
        "https://cdn.jsdelivr.net/npm/@editorjs/simple-image",
        "https://cdn.jsdelivr.net/npm/@editorjs/nested-list@latest",
        "https://cdn.jsdelivr.net/npm/@editorjs/checklist@latest",
        "https://cdn.jsdelivr.net/npm/@editorjs/delimiter@latest",
        "https://cdn.jsdelivr.net/npm/editorjs-html@3.4.0/build/edjsHTML.js",
      ],
    };
  },
  // components: {
  //   VuePerfectScrollbar,
  // },
  mounted() {
    // const links = ;
    if (!window.EditorJS) {
      this.loadNextPlugin();
    } else {
      this.initializeEditor();
    }
    // const editorScript = document.createElement("script");
    // editorScript.src = "https://cdn.jsdelivr.net/npm/@editorjs/editorjs@latest";
    // editorScript.onload = this.initializeEditor;
    // document.head.appendChild(editorScript);

    //   const links = {
    //     header:{
    //         configs:{
    //             class: window.Header,
    //             inlineToolbar: ['link'],
    //         },
    //         link:"https://cdn.jsdelivr.net/npm/@editorjs/header@latest"
    //     },
    //     paragraph:{
    //         configs:{
    //             class: window.Paragraph,
    //             inlineToolbar: true,
    //         },
    //         link:"https://cdn.jsdelivr.net/npm/@editorjs/paragraph"
    //     },
    // quote:{
    //     configs:{
    //         class: window.Paragraph,
    //         inlineToolbar: true,
    //     },
    //     link:"https://www.jsdelivr.com/package/npm/@editorjs/quote@latest"
    // },
    // warning:{
    //     configs:{
    //         class: window.Paragraph,
    //         inlineToolbar: true,
    //     },
    //     link:"https://cdn.jsdelivr.net/npm/@editorjs/warning@latest"
    // },
    //   }
    //   for (let link in links){
    //     // console.log(link)
    //       const scriptLink = document.createElement('script');
    //       scriptLink.src = links[link].link;
    //       console.log(scriptLink);
    //       this.loadEditor()
    //       document.head.appendChild(scriptLink);
    //     //   scriptLink.onload = this.addPlugin(link, links[link].configs)
    //   }
  },
  methods: {
    loadNextPlugin() {
      this.count += 1;
      if (this.count < this.links.length) {
        const script = document.createElement("script");
        script.src = this.links[this.count];
        script.onload = this.loadNextPlugin;
        document.head.appendChild(script);
      } else {
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/@editorjs/editorjs@latest";
        script.onload = this.initializeEditor;
        document.head.appendChild(script);
      }
    },
    addPlugin(plugin, configs) {
      this.editor.tools[plugin] = configs;
    },
    loadEditor() {},
    // initializeTitleEditor(){
    //   if(window.EditorJS){
    //     this.titleEditor = new window.EditorJS({
    //       holder:this.$refs.title,
    //       placeholder:"Title",
    //       autofocus:false,
    //       inlineToolbar: ['link', 'marker', 'bold', 'italic'],
    //       blocks : [{type:"customHeading", data:{}}],
    //       tools:{
    //         customHeading:{
    //           class:CustomHeading,
    //           inlineToolbar: true,
    //         }
    //       },
    //       // hideToolbar:true,
    //       defaultBlock:'customHeading'
    //       // readOnly:true,
    //       // onChange: () => {
    //       //   // Handle change events if needed
    //       // },
    //     })
    //   }else {
    //     console.error(
    //       "EditorJS is not defined. Script may not have loaded correctly."
    //     );
    //   }
    //   this.initializeEditor();
    // },
    initializeEditor() {
      // Make sure EditorJS is defined after the script is loaded
      if (window.EditorJS) {
        // const Header = window.Header;
        this.editor?.clear();
        this.editor = new window.EditorJS({
          holder: this.$refs.editor,
          placeholder: "Add Instructions",
          //   inlineToolbar: ['link', 'marker', 'bold', 'italic'],
          autofocus: false,
          blocks: [{ type: "paragraph", data: {} }],
          minHeight: 0,
          onChange: () => {
            this.saveContent();
          },
          // onReady: () => {
          //   console.log("Editor.js is ready to work!");
          // },
          data: {},
          tools: {
            // Header,
            customHeading: CustomHeading,
            image: {
              class: window.ImageTool,
              inlineToolbar: true,
              config: {
                field: "thumbnail",
                uploader: {
                  async uploadByFile(file) {
                    const formData = new FormData();
                    formData.append("thumbnail", file);
                    var fileSizeInMB = file.size / (1024 * 1024);
                    if (fileSizeInMB <= 1) {
                      const response = await axios.post(
                        `https://api-core-gateway.getboarded.com/alex/library/assignment/upload-library-item-thumbnail`,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                          withCredentials: false,
                        }
                      );
                      if (response?.data?.data?.file?.url) {
                        return {
                          success: 1,
                          file: {
                            url: response?.data?.data?.file?.url,
                          },
                        };
                      }
                    } else {
                      let notificationDetail = {
                        message:
                          "File size exceeds 1 MB. Please choose a smaller file.",
                        variant: "danger",
                      };
                      this.showToast(notificationDetail);
                    }
                  },
                },
              },
            },
            paragraph: {
              class: window.Paragraph,
              // inlineToolbar: true,
            },
            //         header: {
            //   class: window.Header,
            //   inlineToolbar: true, // This is an inline property for the Header tool
            //   config: {
            //     placeholder: 'Enter a header',
            //     levels: [2, 3, 4],
            //     defaultLevel: 2,
            //   },
            // },
            // header: {
            //   class: window.Header,
            //   inlineToolbar: ['link'],
            // },
            quote: {
              class: window.Quote,
              inlineToolbar: true,
            },
            // warning: {
            //     class: window.Warning,
            //     inlineToolbar: true,
            //     config: {
            //         titlePlaceholder: 'Title',
            //         messagePlaceholder: 'Message',
            //     },
            // },
            list: {
              class: window.List && window.NestedList,
              inlineToolbar: true,
              config: {
                defaultStyle: "unordered",
              },
            },
            // checklist: {
            //     class: window.Checklist,
            //     inlineToolbar: true,
            // },
            linkTool: {
              class: window.LinkTool,
            },
            // table: {
            //     class: window.Table,
            //     inlineToolbar: true,
            //     config: {
            //         rows: 2,
            //         cols: 3,
            //     },
            // },
            // attaches: {
            //     class: window.AttachesTool,
            //     config: {
            //         endpoint: 'http://localhost:8008/uploadFile'
            //     }
            // },
            delimiter: window.Delimiter,
            // image:window.SimpleImage
            // tooltip: {
            //     class: window.Tooltip,
            //     config: {
            //         location: 'left',
            //         highlightColor: '#FFEFD5',
            //         underline: true,
            //         backgroundColor: '#154360',
            //         textColor: '#FDFEFE',
            //         holder: 'editorId',
            //     }
            // }
            // Add your Editor.js configuration here
          },
        });
      } else {
        console.error(
          "EditorJS is not defined. Script may not have loaded correctly."
        );
      }
    },
    saveContent() {
      if (this.editor) {
        this.editor
          .save()
          .then((outputData) => {
            const edjsParser = window.edjsHTML();
            let htmlCompatibleData = edjsParser.parse(outputData);
            this.$store.commit(
              "library/SELECT_ITEM_INSTRUCTIONS",
              htmlCompatibleData
            );
          })
          .catch((error) => {
            console.error("Saving failed: ", error);
          });
      } else {
        console.error("Editor instance is not available.");
      }
      // this.$emit('save')
    },
  },
};
</script>

<style lang="scss" scoped>
.editor__container {
  position: relative;
  z-index: 11;
  .title {
    padding: 0.5em 1em;
    border: 1px solid #d8d6de;
    border-radius: 4px;
    height: 4rem;
  }
  .editor {
    border: 1px solid #d8d6de;
    padding: 0.5em 1em;
    border-radius: 4px;
    width: 100%;
    margin: 0.25rem 0rem;
    min-height: 10rem;
    // max-height: 5rem;
  }
}
</style>

<style>
/* editor css */
.codex-editor--empty
  .ce-block:first-child
  .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
  color: #b9b9c5;
  font-size: 1.125rem;
  letter-spacing: 0.02rem;
}
</style>
