<template>
  <div
    class="event__card__container cursor-pointer"
    :class="{
      event__card__container__highlight:
        itemId === event.itemId ||
        (selectedItem &&
          selectedItem.some((item) => item.itemId === event.itemId)),
    }"
    @mouseenter="itemId = event.itemId"
    @mouseleave="itemId = ''"
  >
    <div
      class="item__card__checkbox"
      :class="{
        item__card__checkbox__highlight:
          itemId === event.itemId ||
          (selectedItem &&
            selectedItem.some((item) => item.itemId === event.itemId)),
      }"
    >
      <CheckboxWhite :value="event" @checkbox-value="getSelectedValue" />
    </div>
    <div class="img__container">
      <img
        @click="redirectPage(event)"
        :src="event.imageUrl ? event.imageUrl : randomFeedImage"
        @error="randomFeedImage(event, event.itemId)"
      />
    </div>
    <div class="content__container" @click="redirectPage(event)">
      <h3 class="title">
        {{ event.title }}
      </h3>
      <div class="detail d-flex align-items-center">
        <img
          class="mr-1 img"
          src="@/assets/images/icons/calender.svg"
          alt="Calender"
        />
        <span>
          <span class="main">{{ event.startDate }}</span>
          <!-- <h5 class="m-0 sub">5pm - 6pm</h5> -->
        </span>
      </div>

      <div class="detail">
        <img
          class="mr-1 img"
          src="@/assets/images/icons/location.svg"
          alt="Calender"
        />
        <span>
          <h4 class="main">{{ event.location }}</h4>
          <!-- <h5 class="m-0 sub">36 Guild Street London, UK</h5> -->
        </span>
      </div>
      <div class="detail">
        <img
          class="mr-1 img"
          :src="event.sourceLogoUrl"
          @error="event.target.src = 'https://source.boringavatars.com/ring'"
        />
        <span>
          <h4 class="main">{{ event.organizer }}</h4>
          <h5 class="m-0 sub">Organizer</h5>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxWhite from "../common/CheckboxWhite.vue";
import orgMixin from "@/mixins/orgMixin.js";

export default {
  name: "EventCard",
  components: {
    CheckboxWhite,
  },
  mixins: [orgMixin],
  data() {
    return {
      checkBox: false,
      itemId: "",
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedItem() {
      return this.$store.state.library.selectedItem;
    },
  },
  methods: {
    randomFeedImage(item) {
      let i = 15; // i = number of given random images
      let alternateImgUrl = require(`@/assets/images/library/blank-feed/${[
        Math.floor(Math.random() * i),
      ]}.png`);
      item.imageUrl = alternateImgUrl;
    },
    getSelectedValue(item) {
      this.$store.commit("library/UPDATE_DISTRIBUTED_ITEM_LIST", item);
    },
    redirectPage(item) {
      const { gbuid, gborgid } = this.orgObj;
      let queryParams = {
        itemId: item.itemId,
        type: item.itemType || item.type,
        gbuid: gbuid,
        gborgid: gborgid,
      };
      const routeData = this.$router.resolve({
        name: "Redirect Page",
        query: queryParams,
      });
      return window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.event__card__container {
  border-radius: 0.35rem;
  overflow: hidden;
  position: relative;
  border: 2px solid #efefef;
  display: flex;
  min-height: 15rem;
  max-height: auto;
  align-content: start;
  justify-content: start;
  width: 100%;
  flex-direction: column;
  gap: 0rem;

  .item__card__checkbox {
    margin-right: -4rem;
  }

  .img__container {
      width: 100%;
      height: fit-content;

    img {
      width: 100%;
      height: 15rem;
      object-fit: cover;
    }
  }

  .content__container {
    padding: 2rem 1rem;

    .title {
      color: #000000;
      font-size: 1.5rem;
      font-weight: 600;
      text-wrap: wrap;
    }

    .detail {
      width: auto;
      margin-top: 1rem;
      display: flex !important;
      align-items: center !important;
      flex-wrap: wrap;

      .img {
        width: 2.75rem;
        height: 2.75rem;
      }

      span{
        height: fit-content;
      }

      .main {
        color: #000000;
        font-size: 1rem;
        font-weight: 500;
        height: fit-content;
      }

      .sub {
        color: #808080;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
  &__highlight {
    border: 2px solid #ff6584;
  }
  &:hover {
    border: 2px solid #ff6584;
    box-shadow: 0px 0px 14px 5px rgb(255 101 132 / 40%);
  }
}

@media only screen and (max-width: 600px) {
  .event__card__container {
    flex-direction: column;
    gap: 0rem;

  }
}
</style>
